import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { indotechButtonStyle } from '../../utils/styles';

function IndotechLinkButton({ title, bgColor, textColor, size, linkTo, containerStyle, textStyle }) {
    return (
        <Link
            to={linkTo}
            className={containerStyle}
        >
            <div className={`${textColor} text-center font-semibold hover:opacity-50 ${indotechButtonStyle(size)} ${bgColor} ${textStyle}`}>{title}</div>
        </Link>
    );
}

IndotechLinkButton.propTypes = {
    title: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    linkTo: PropTypes.string,
    containerStyle: PropTypes.string,
    textStyle: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

IndotechLinkButton.defaultProps = {
    title: '',
    bgColor: 'bg-indotech-primary_blue',
    textColor: 'text-white',
    linkTo: '',
    containerStyle: '',
    textStyle: '',
    size: 'md',
};

export default IndotechLinkButton;
