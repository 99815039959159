import React from 'react';
import PropTypes from 'prop-types';

function L5RadioLabel({ textStyle, color, label }) {
    return (
        <span
            className={`mx-2 font-semibold ${textStyle}`}
            style={{ color: color }}
        >
            {label}
        </span>
    );
}

L5RadioLabel.propTypes = {
    textStyle: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

L5RadioLabel.defaultProps = {
    textStyle: '',
    color: '',
    label: '',
};

export default L5RadioLabel;
