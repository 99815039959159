import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isDev } from '../utils/utils';

import { sendMail } from '../api';

import Banner from '../components/contact-us/Banner';
import ContactForm from '../components/contact-us/ContactForm';
import ContactInformationList from '../components/contact-us/ContactInformationList';
import Footer from '../components/footer/Footer';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import IndotechButton, { BUTTON_TYPE } from '../components/ui-elements/IndotechButton';
import Section from '../components/ui-elements/Section';

import { contact } from '../constant/contact';
import { contactUsMail } from '../constant/messages';
import { useEmailValidation } from '../hooks/useEmailValidation';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const defaultForm = {
    firstName: '',
    lastName: '',
    businessName: '',
    jobTitle: '',
    email: '',
    phone: '',
    industry: '',
    productRange: '',
    message: '',
    communication: '',
};

function ContactUs() {
    const [t] = useTranslation('contactUs');
    const { isMobile } = useWindowDimensions();
    const [form, setForm] = useState(defaultForm);
    const [isEmailSending, setIsEmailSending] = useState(false);

    const isValidEmail = useEmailValidation(form.email);

    const isFormValid = isValidEmail && form.firstName !== '' && form.lastName !== '';

    async function sendContactForm() {
        if (!isFormValid) {
            return;
        }

        setIsEmailSending(true);

        const mailData = {
            from: 'no-reply@indotech.ca',
            to: isDev ? 'service@loopfive.ca' : contact.EMAIL.value,
            subject: 'New contact - Indotech website',
            html: contactUsMail(form),
        };

        try {
            await sendMail(mailData, t('contact_sent'), t('error'));
            setForm({ ...defaultForm });
        } catch (e) {
            /* empty */
        } finally {
            setIsEmailSending(false);
        }
    }

    return (
        <AnimatedPage>
            <Section classStyle={'p-4 sm:px-12 sm:pt-28'}>
                <Banner />
            </Section>

            <Section classStyle={'p-4 sm:px-12'}>
                <ContactInformationList />
            </Section>

            <Section classStyle={'p-4 sm:px-12'}>
                <ContactForm
                    form={form}
                    setForm={setForm}
                />
            </Section>

            <Section classStyle={'p-4 sm:px-12'}>
                <div className={`flex w-full max-w-screen-xl justify-end py-8 ${isMobile && 'px-4'}`}>
                    <IndotechButton
                        classStyle={`px-16 py-4 ${isMobile && 'w-full'}`}
                        isLoading={isEmailSending}
                        onClick={sendContactForm}
                        type={BUTTON_TYPE.BLUE}
                        isDisabled={!isFormValid}
                    >
                        <div className={'text-lg font-bold text-white'}>{t('send').toUpperCase()}</div>
                    </IndotechButton>
                </div>
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

export default ContactUs;
