import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getModalBgColor } from '../../../utils/styles';

import { LIGHT } from '../../../constant/navbarTheme';
import { products } from '../../../constant/products/products';
import NavLink from '../NavLink';
import DrawerHeader from './DrawerHeader';

function ProductsDrawerContent({ theme, setProductsToggled, onLinkClick }) {
    const [t] = useTranslation(['navbar, ourProducts']);

    const location = useLocation();

    return (
        <>
            <DrawerHeader
                theme={theme}
                setToggled={setProductsToggled}
                title={t('products', { ns: 'navbar' })}
            />

            <div className={`flex h-full flex-col items-center justify-center ${getModalBgColor(theme)} pb-16`}>
                <>
                    {products.map((product) => {
                        return (
                            <NavLink
                                key={product.titleKey}
                                title={t('tech-roll', { ns: 'ourProducts' })}
                                secondTitle={t(`product.${product.titleKey}`, { ns: 'navbar' })}
                                secondTextColor={'text-indotech-orange'}
                                theme={theme}
                                linkTo={product.linkTo}
                                active={location.pathname === product.linkTo}
                                onLinkClick={onLinkClick}
                            />
                        );
                    })}
                </>
            </div>
        </>
    );
}

ProductsDrawerContent.propTypes = {
    theme: PropTypes.string,
    setProductsToggled: PropTypes.func,
    onLinkClick: PropTypes.func,
};

ProductsDrawerContent.defaultProps = {
    theme: LIGHT,
    setProductsToggled: () => {},
    onLinkClick: () => {},
};

export default ProductsDrawerContent;
