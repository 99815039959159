import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_LOOPFIVE_SERVICE_URL,
});

export default {
    async sendMail(data) {
        return api.post('/mail/send', data);
    },
};
