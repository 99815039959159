import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DistributorCardContent from './DistributorCardContent';

import { HOME } from '../../constant/navigation/routeNames';

function DistributorCard({ logo, title, description, linkTo }) {
    const [showBackCard, setShowBackCard] = useState(false);

    return (
        <div
            className={'m-4 flex h-72 justify-center sm:h-80'}
            onMouseEnter={() => setShowBackCard(true)}
            onMouseLeave={() => setShowBackCard(false)}
        >
            <DistributorCardContent
                logo={logo}
                title={title}
                description={description}
                linkTo={linkTo}
                showBackCard={showBackCard}
            />
        </div>
    );
}

DistributorCard.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    linkTo: PropTypes.string,
};

DistributorCard.defaultProps = {
    logo: '',
    title: '',
    description: '',
    linkTo: HOME,
};

export default DistributorCard;
