import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import PropTypes from 'prop-types';

import { getHambugerColor, getModalBgColor, navLinkTextColor } from '../../../utils/styles';

import { LIGHT } from '../../../constant/navbarTheme';

function DrawerHeader({ theme, title, setToggled }) {
    const [t] = useTranslation('navbar');

    return (
        <div className={`${getModalBgColor(theme)} flex items-center px-2 py-4`}>
            <div
                className={'flex basis-1/3 flex-row items-center'}
                onClick={() => setToggled(false)}
            >
                <MdKeyboardArrowLeft
                    size={35}
                    color={getHambugerColor(theme)}
                />

                <div className={`text-center ${navLinkTextColor(theme)}`}>{t('menu')}</div>
            </div>

            <div className={'basis-1/3'}>
                <div className={`text-center font-semibold ${navLinkTextColor(theme)}`}>{title}</div>
            </div>
        </div>
    );
}

DrawerHeader.propTypes = {
    theme: PropTypes.string,
    setToggled: PropTypes.func,
    title: PropTypes.string,
};

DrawerHeader.defaultProps = {
    theme: LIGHT,
    setToggled: () => {},
    title: '',
};

export default DrawerHeader;
