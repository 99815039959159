import React from 'react';

import OurHistory from '../components/about-us/OurHistory';
import OurTeam from '../components/about-us/OurTeam';
import OurValues from '../components/about-us/OurValues';
import Footer from '../components/footer/Footer';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import Section from '../components/ui-elements/Section';

import aboutUsBg from '../assets/pictures/backgroundImages/about_us_bg.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';

function AboutUsPage() {
    const { isMobile } = useWindowDimensions();

    return (
        <AnimatedPage>
            <img
                src={aboutUsBg}
                className={`${isMobile ? 'h-[600px]' : ''} object-cover opacity-90 brightness-25 sm:w-full`}
                alt={'background image'}
            />

            <Section classStyle={'absolute top-1/3'}>
                <OurHistory />
            </Section>

            <Section classStyle={'bg-indotech-orange'}>
                <OurValues />
            </Section>

            <Section classStyle={'mb-10 sm:mb-20'}>
                <OurTeam />
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

export default AboutUsPage;
