import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SpecificationTabledValue from './SpecificationTabledValue';

function SpecificationValue({ specificationValue }) {
    const [t] = useTranslation('ourProducts');

    if (typeof specificationValue === 'object') {
        return <SpecificationTabledValue specificationValue={specificationValue} />;
    }

    return <div className={'ml-1 flex basis-3/5 items-center justify-end text-right text-indotech-primary_blue'}>{t(specificationValue)}</div>;
}

SpecificationValue.propTypes = {
    specificationValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SpecificationValue.defaultProps = {
    specificationValue: '',
};

export default SpecificationValue;
