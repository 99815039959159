import React from 'react';
import { BiSolidLockOpen as LockIcon } from 'react-icons/bi';
import { BsGearWideConnected as GearIcon, BsLightningChargeFill as LightningIcon } from 'react-icons/bs';
import { FaClock as ClockIcon, FaWrench as WrenchIcon } from 'react-icons/fa';
import { MdSensorDoor as DoorIcon } from 'react-icons/md';

import { Colors } from '../../utils/Colors';

import bottomBarImg from '../../assets/pictures/components/bottomBar.svg';
import brakesImg from '../../assets/pictures/components/brakes.svg';
import curtainImg from '../../assets/pictures/components/curtain.svg';
import endlocksImg from '../../assets/pictures/components/endlocks.svg';
import guidesImg from '../../assets/pictures/components/guides.svg';
import operatorImg from '../../assets/pictures/components/operator.svg';
import springsImg from '../../assets/pictures/components/springs.svg';
import windbarsImg from '../../assets/pictures/components/windbars.svg';

export const litePdfs = [];

export const liteInfoKeys = [
    'products.lite.infos.motor',
    'products.lite.infos.cce',
    'products.lite.infos.curtain',
    'products.lite.infos.guides',
    'products.lite.infos.springless',
    'products.lite.infos.selfRepair',
    'products.lite.infos.height',
    'products.lite.infos.bar',
    'products.lite.infos.safety',
];

export const liteBenefits = [
    {
        benefitKey: 'products.lite.benefits.downtime',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <LightningIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lite.benefits.energy',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <GearIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lite.benefits.costRepair',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <WrenchIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lite.benefits.maintenance',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <ClockIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lite.benefits.impact',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <DoorIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lite.benefits.pricing',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <LockIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
];

export const liteSpecificationKeys = [
    {
        label: 'specificationsLabel.cycles',
        value: 'products.lite.specificationsValues.cycles',
    },
    {
        label: 'specificationsLabel.drive_system',
        value: [
            {
                label: 'products.lite.specificationsValues.drive_system.chain_driven_operator',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.direct_drive_operator',
                value: 'specificationSecondValues.available_on_req',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.integrated_brake_system',
                value: 'specificationSecondValues.electromagnetic',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.one_phase_208_to_240_volts',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.three_phase_208_to_575_volts',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.chain_hoist_for_manual_operation',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.speed_variator',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.drive_system.control_panel',
                value: 'specificationSecondValues.standard_with_vfd_plc',
            },
        ],
    },
    {
        label: 'specificationsLabel.curtain',
        value: [
            {
                label: 'products.lite.specificationsValues.curtain.sbr',
                value: 'specificationSecondValues.standard_black',
            },
            {
                label: 'products.lite.specificationsValues.curtain.thickness',
                value: 'specificationSecondValues.blue_curtain_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.curtain_idler',
        value: 'specificationSecondValues.unavailable',
    },
    {
        label: 'specificationsLabel.temperature',
        value: 'products.lite.specificationsValues.temperature',
    },
    {
        label: 'specificationsLabel.endlock_system',
        value: 'products.lite.specificationsValues.endlock_system',
    },
    {
        label: 'specificationsLabel.bottom_bar',
        value: 'products.lite.specificationsValues.bottom_bar',
    },
    {
        label: 'specificationsLabel.guide',
        value: 'products.lite.specificationsValues.guide',
    },
    {
        label: 'specificationsLabel.inertia_brake',
        value: 'specificationSecondValues.unavailable',
    },
    {
        label: 'specificationsLabel.frame_color',
        value: [
            {
                label: 'products.lite.specificationsValues.frame_color.standard_yellow',
                value: 'specificationSecondValues.other_colors_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.safety',
        value: 'products.lite.specificationsValues.safety',
    },
    {
        label: 'specificationsLabel.mounting',
        value: [
            {
                label: 'products.lite.specificationsValues.mounting.concrete',
                value: 'products.lite.specificationsValues.mounting.steel',
            },
        ],
    },
    {
        label: 'specificationsLabel.balance_system',
        value: [
            {
                label: 'products.lite.specificationsValues.balance_system.springs',
                value: 'specificationSecondValues.not_required',
            },
            {
                label: 'products.lite.specificationsValues.balance_system.counter_weight',
                value: 'specificationSecondValues.not_required',
            },
        ],
    },
    {
        label: 'specificationsLabel.options',
        value: [
            {
                label: 'products.lite.specificationsValues.options.windows',
                value: 'specificationSecondValues.available_on_req',
            },
            {
                label: 'products.lite.specificationsValues.options.pneumatic_sensor',
                value: 'specificationSecondValues.reversing_edges',
            },
            {
                label: 'products.lite.specificationsValues.options.reflective_stripes',
                value: 'specificationSecondValues.yellow_and_gray',
            },
            {
                label: 'products.lite.specificationsValues.options.photocells',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lite.specificationsValues.options.protective_covers',
                value: 'specificationSecondValues.available_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.other_electric',
        value: 'products.lite.specificationsValues.other_electric',
    },
    {
        label: 'specificationsLabel.wind_res',
        value: 'products.lite.specificationsValues.wind_res',
    },
];

export const liteComponents = [
    {
        id: 'curtain',
        number: 1,
        title: 'products.lite.components.curtain.title',
        description: 'products.lite.components.curtain.description',
        img: curtainImg,
    },
    {
        id: 'endLocks',
        number: 2,
        title: 'products.lite.components.endLocks.title',
        description: 'products.lite.components.endLocks.description',
        img: endlocksImg,
    },
    {
        id: 'bottomBar',
        number: 3,
        title: 'products.lite.components.bottomBar.title',
        description: 'products.lite.components.bottomBar.description',
        img: bottomBarImg,
    },
    {
        id: 'guideRails',
        number: 4,
        title: 'products.lite.components.guideRails.title',
        description: 'products.lite.components.guideRails.description',
        img: guidesImg,
    },
    {
        id: 'windBars',
        number: 5,
        title: 'products.lite.components.windBars.title',
        description: 'products.lite.components.windBars.description',
        img: windbarsImg,
        notAvailable: true,
    },
    {
        id: 'springs',
        number: 6,
        title: 'products.lite.components.springs.title',
        description: 'products.lite.components.springs.description',
        img: springsImg,
        notAvailable: true,
    },
    {
        id: 'operators',
        number: 7,
        title: 'products.lite.components.operators.title',
        description: 'products.lite.components.operators.description',
        img: operatorImg,
    },
    {
        id: 'brakes',
        number: 8,
        title: 'products.lite.components.brakes.title',
        description: 'products.lite.components.brakes.description',
        img: brakesImg,
        notAvailable: true,
    },
];
