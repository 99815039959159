import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaImage as GalleryIcon } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

import L5ImagePreviewModal from '../ui-elements/L5ImagePreviewModal';

function ProductCardHeader({ valueKey, linkTo, images }) {
    const [t] = useTranslation('ourProducts');

    const location = useLocation();

    const [selectedImage, setSelectedImage] = useState(0);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className={'flex flex-row justify-between'}>
                <Link
                    className={'flex flex-row hover:opacity-50'}
                    to={linkTo}
                    state={{ prevPath: location.pathname }}
                >
                    <div className={'mr-1 text-center text-xl font-semibold text-indotech-primary_blue sm:mr-2 sm:text-3xl'}>{t('tech-roll')}</div>

                    <div className={`text-center text-xl font-semibold text-indotech-orange sm:text-3xl`}>{t(`products.${valueKey}.title`)}</div>
                </Link>

                <div
                    onClick={() => setShowModal(true)}
                    style={{ cursor: 'pointer' }}
                    className={'flex flex-row items-center text-center text-lg font-semibold text-indotech-primary_blue underline hover:opacity-50 sm:text-2xl'}
                >
                    <GalleryIcon
                        color={Colors.primary_blue}
                        className={'mr-1 sm:mr-2'}
                    />

                    {t('images')}
                </div>
            </div>

            <L5ImagePreviewModal
                isOpened={showModal}
                setOpened={(e) => setShowModal(e)}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                images={images}
            />
        </>
    );
}

ProductCardHeader.propTypes = {
    valueKey: PropTypes.string,
    linkTo: PropTypes.string,
    images: PropTypes.object,
};

ProductCardHeader.defaultProps = {
    valueKey: '',
    linkTo: '',
    images: [],
};

export default ProductCardHeader;
