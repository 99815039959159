import React from 'react';
import PropTypes from 'prop-types';

function SectionTitle({ classStyle, title }) {
    return <div className={`text-4xl font-semibold sm:text-5xl ${classStyle}`}>{title}</div>;
}

SectionTitle.propTypes = {
    classStyle: PropTypes.string,
    title: PropTypes.string,
};

SectionTitle.defaultProps = {
    classStyle: '',
    title: '',
};

export default SectionTitle;
