import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { HOME } from '../../constant/navigation/routeNames';

function DistributorCardBack({ title, description, linkTo }) {
    const [t] = useTranslation('ourDistributors');

    return (
        <div
            className={
                'flex w-[18rem] flex-col justify-between rounded-2xl bg-indotech-primary_blue p-4 shadow-md shadow-gray-400 drop-shadow-lg sm:w-[22rem] sm:p-8'
            }
        >
            <div>
                <div className={'mb-1 line-clamp-1 text-left text-lg font-semibold text-white sm:mb-2'}>{title}</div>

                <div className={'overflow-hidden text-left text-justify text-white'}>{t(description)}</div>
            </div>

            <Link
                className={'my-4 flex w-full flex-row items-center justify-end text-sm text-white underline sm:text-base'}
                to={linkTo}
            >
                {t('learn_more').toUpperCase()}
                <MdKeyboardArrowRight size={25} />
            </Link>
        </div>
    );
}

DistributorCardBack.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    linkTo: PropTypes.string,
};

DistributorCardBack.defaultProps = {
    title: '',
    description: '',
    linkTo: HOME,
};

export default DistributorCardBack;
