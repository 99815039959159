export const Distributors = {
    APEX: 'APEX',
    DEK: 'DEK',
    CFM_GROUP: 'CFM_GROUP',
    LG_DOORS: 'LG_DOORS',
    ANDRE_INDUSTRIES: 'ANDRE_INDUSTRIES',
    UNIVERSAL_DOORS: 'UNIVERSAL_DOORS',
    LONGPRE_DOORS: 'LONGPRE_DOORS',
    SIMS: 'SIMS',
    VALMART: 'VALMART',
    DRAKKAR: 'DRAKKAR',
};
