import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import PropTypes from 'prop-types';

import { l5RadioButtonStyle } from '../../../../utils/styles';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import L5RadioDot from '../../../ui-elements/L5RadioButton/L5RadioDot';
import L5RadioLabel from '../../../ui-elements/L5RadioButton/L5RadioLabel';

function DoorRadioBanner({ value, label, labelSuffix, selectedOption, handleOptionChange, showMoreInfo, setShowMoreInfo }) {
    const { isMobile } = useWindowDimensions();

    const radioStyle = l5RadioButtonStyle('md');

    const radioChecked = selectedOption === value;

    function handleDrawerToggle() {
        setShowMoreInfo(!showMoreInfo);
    }

    function onSelectDoor() {
        if (radioChecked && showMoreInfo) {
            // if already checked, can re-click to close
            setShowMoreInfo(false);

            return;
        }

        if (!showMoreInfo) {
            setShowMoreInfo(true);
        }

        handleOptionChange({ target: { value } });
    }

    return (
        <div className={'flex w-full flex-row items-center justify-between rounded-xl bg-indotech-primary_blue px-4 py-2'}>
            <div
                className={'flex flex-row items-center'}
                onClick={onSelectDoor}
            >
                <L5RadioDot
                    outerStyle={radioStyle.outer}
                    innerStyle={radioStyle.inner}
                    radioChecked={radioChecked}
                    color={'white'}
                />

                <L5RadioLabel
                    label={label}
                    textStyle={`${radioStyle.text}`}
                    color={'white'}
                />

                {!isMobile && (
                    <L5RadioLabel
                        label={labelSuffix}
                        textStyle={`${radioStyle.subText}`}
                        color={'white'}
                    />
                )}
            </div>

            <div
                className={'flex flex-1 items-center justify-end'}
                onClick={handleDrawerToggle}
            >
                {showMoreInfo ? (
                    <IoIosArrowUp
                        color={'white'}
                        size={20}
                    />
                ) : (
                    <IoIosArrowDown
                        color={'white'}
                        size={20}
                    />
                )}
            </div>
        </div>
    );
}

DoorRadioBanner.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    labelSuffix: PropTypes.string,
    selectedOption: PropTypes.string,
    handleOptionChange: PropTypes.func,
    showMoreInfo: PropTypes.bool,
    setShowMoreInfo: PropTypes.func,
};

DoorRadioBanner.defaultProps = {
    value: '',
    label: '',
    labelSuffix: '',
    selectedOption: '',
    handleOptionChange: () => {},
    showMoreInfo: false,
    setShowMoreInfo: () => {},
};

export default DoorRadioBanner;
