import React from 'react';
import { BiSolidLockOpen as LockIcon } from 'react-icons/bi';
import { BsGearWideConnected as GearIcon, BsLightningChargeFill as LightningIcon } from 'react-icons/bs';
import { FaClock as ClockIcon, FaWrench as WrenchIcon } from 'react-icons/fa';
import { MdSensorDoor as DoorIcon } from 'react-icons/md';
import { TiWeatherDownpour as RainIcon } from 'react-icons/ti';

import { Colors } from '../../utils/Colors';

import drawing_EN from '../../assets/pdfDocuments/EN/lhr/Dessin LHDR-EN.PDF';
import guide_EN from '../../assets/pdfDocuments/EN/lhr/lhr-manual-april-2023-eng.pdf';
import drawing_FR from '../../assets/pdfDocuments/FR/lhr/Dessin LHDR-FR.PDF';
import guide_FR from '../../assets/pdfDocuments/FR/lhr/lhr-tech-roll-manuel-francais-avril-2023.pdf';
import bottomBarImg from '../../assets/pictures/components/bottomBar.svg';
import brakesImg from '../../assets/pictures/components/brakes.svg';
import curtainImg from '../../assets/pictures/components/curtain.svg';
import endlocksImg from '../../assets/pictures/components/endlocks.svg';
import guidesImg from '../../assets/pictures/components/guides.svg';
import operatorImg from '../../assets/pictures/components/operator.svg';
import springsImg from '../../assets/pictures/components/springs.svg';
import windbarsImg from '../../assets/pictures/components/windbars.svg';

export const lhrPdfs = [
    { pdfTitle: 'products.lhr.pdfs.installationGuide', pdfLink: { fr: guide_FR, en: guide_EN } },
    { pdfTitle: 'products.lhr.pdfs.drawing', pdfLink: { fr: drawing_FR, en: drawing_EN } },
];
export const lhrInfoKeys = [
    'products.lhr.infos.maxHeight',
    'products.lhr.infos.curtainWidth',
    'products.lhr.infos.operator',
    'products.lhr.infos.guides',
    'products.lhr.infos.finish',
    'products.lhr.infos.springs',
    'products.lhr.infos.configPanel',
    'products.lhr.infos.bottomBar',
    'products.lhr.infos.brush',
    'products.lhr.infos.bands',
    'products.lhr.infos.securityBorder',
    'products.lhr.infos.ideal',
    'products.lhr.infos.minClearance',
];

export const lhrBenefits = [
    {
        benefitKey: 'products.lhr.benefits.waterproof',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <RainIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.speed',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <LightningIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.costNoProduction',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <GearIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.costRepair',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <WrenchIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.maintenance',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <ClockIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.impact',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <DoorIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
    {
        benefitKey: 'products.lhr.benefits.security',
        icon: ({ size = 40, color = Colors.primary_blue, className = '' } = { size: 40, color: Colors.primary_blue, className: '' }) => (
            <LockIcon
                size={size}
                color={color}
                className={className}
            />
        ),
    },
];

export const lhrSpecificationKeys = [
    {
        label: 'specificationsLabel.cycles',
        value: 'products.lhr.specificationsValues.cycles',
    },
    {
        label: 'specificationsLabel.drive_system',
        value: [
            {
                label: 'products.lhr.specificationsValues.drive_system.chain_driven_operator',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.direct_drive_operator',
                value: 'specificationSecondValues.available_on_req',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.integrated_brake_system',
                value: 'specificationSecondValues.electromagnetic',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.one_phase_208_to_240_volts',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.three_phase_208_to_575_volts',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.chain_hoist_for_manual_operation',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.speed_variator',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.drive_system.control_panel',
                value: 'specificationSecondValues.standard_with_vfd_plc',
            },
        ],
    },
    {
        label: 'specificationsLabel.curtain',
        value: [
            {
                label: 'products.lhr.specificationsValues.curtain.sbr',
                value: 'specificationSecondValues.standard_black',
            },
            {
                label: 'products.lhr.specificationsValues.curtain.thickness',
                value: 'specificationSecondValues.blue_curtain_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.curtain_idler',
        value: 'products.lhr.specificationsValues.curtain_idler',
    },
    {
        label: 'specificationsLabel.temperature',
        value: 'products.lhr.specificationsValues.temperature',
    },
    {
        label: 'specificationsLabel.endlock_system',
        value: 'products.lhr.specificationsValues.endlock_system',
    },
    {
        label: 'specificationsLabel.bottom_bar',
        value: 'products.lhr.specificationsValues.bottom_bar',
    },
    {
        label: 'specificationsLabel.guide',
        value: 'products.lhr.specificationsValues.guide',
    },
    {
        label: 'specificationsLabel.inertia_brake',
        value: 'products.lhr.specificationsValues.inertia_brake',
    },
    {
        label: 'specificationsLabel.frame_color',
        value: [
            {
                label: 'products.lhr.specificationsValues.frame_color.standard_yellow',
                value: 'specificationSecondValues.other_colors_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.safety',
        value: 'products.lhr.specificationsValues.safety',
    },
    {
        label: 'specificationsLabel.mounting',
        value: [
            {
                label: 'products.lhr.specificationsValues.mounting.concrete',
                value: 'products.lhr.specificationsValues.mounting.steel',
            },
        ],
    },
    {
        label: 'specificationsLabel.balance_system',
        value: [
            {
                label: 'products.lhr.specificationsValues.balance_system.springs',
                value: 'specificationSecondValues.unavailable',
            },
            {
                label: 'products.lhr.specificationsValues.balance_system.counter_weight',
                value: 'specificationSecondValues.unavailable',
            },
        ],
    },
    {
        label: 'specificationsLabel.options',
        value: [
            {
                label: 'products.lhr.specificationsValues.options.windows',
                value: 'specificationSecondValues.available_on_req',
            },
            {
                label: 'products.lhr.specificationsValues.options.pneumatic_sensor',
                value: 'specificationSecondValues.reversing_edges',
            },
            {
                label: 'products.lhr.specificationsValues.options.reflective_stripes',
                value: 'specificationSecondValues.yellow_and_gray',
            },
            {
                label: 'products.lhr.specificationsValues.options.photocells',
                value: 'specificationSecondValues.available',
            },
            {
                label: 'products.lhr.specificationsValues.options.protective_covers',
                value: 'specificationSecondValues.available_on_req',
            },
        ],
    },
    {
        label: 'specificationsLabel.other_electric',
        value: 'products.lhr.specificationsValues.other_electric',
    },
    {
        label: 'specificationsLabel.wind_res',
        value: 'products.lhr.specificationsValues.wind_res',
    },
];

export const lhrComponents = [
    {
        id: 'curtain',
        number: 1,
        title: 'products.lhr.components.curtain.title',
        description: 'products.lhr.components.curtain.description',
        img: curtainImg,
    },
    {
        id: 'endLocks',
        number: 2,
        title: 'products.lhr.components.endLocks.title',
        description: 'products.lhr.components.endLocks.description',
        img: endlocksImg,
    },
    {
        id: 'bottomBar',
        number: 3,
        title: 'products.lhr.components.bottomBar.title',
        description: 'products.lhr.components.bottomBar.description',
        img: bottomBarImg,
    },
    {
        id: 'guideRails',
        number: 4,
        title: 'products.lhr.components.guideRails.title',
        description: 'products.lhr.components.guideRails.description',
        img: guidesImg,
    },
    {
        id: 'windBars',
        number: 5,
        title: 'products.lhr.components.windBars.title',
        description: 'products.lhr.components.windBars.description',
        img: windbarsImg,
        notAvailable: true,
    },
    {
        id: 'springs',
        number: 6,
        title: 'products.lhr.components.springs.title',
        description: 'products.lhr.components.springs.description',
        img: springsImg,
        notAvailable: true,
    },
    {
        id: 'operators',
        number: 7,
        title: 'products.lhr.components.operators.title',
        description: 'products.lhr.components.operators.description',
        img: operatorImg,
    },
    {
        id: 'brakes',
        number: 8,
        title: 'products.lhr.components.brakes.title',
        description: 'products.lhr.components.brakes.description',
        img: brakesImg,
        notAvailable: true,
    },
];
