import React from 'react';
import PropTypes from 'prop-types';

import DistributorCardBack from './DistributorCardBack';

import { HOME } from '../../constant/navigation/routeNames';

function DistributorCardContent({ logo, title, description, linkTo, showBackCard }) {
    if (showBackCard) {
        return (
            <DistributorCardBack
                title={title}
                description={description}
                linkTo={linkTo}
            />
        );
    }

    return (
        <img
            src={logo}
            className={'w-[18rem] rounded-2xl bg-white p-12 shadow-md shadow-gray-400 drop-shadow-lg sm:w-[22rem]'}
            alt={'logo'}
        />
    );
}

DistributorCardContent.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    linkTo: PropTypes.string,
    showBackCard: PropTypes.bool,
};

DistributorCardContent.defaultProps = {
    logo: '',
    title: '',
    description: '',
    linkTo: HOME,
    showBackCard: false,
};

export default DistributorCardContent;
