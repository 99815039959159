import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';
import { pdfItemStyle } from '../../utils/styles';
import i18n from '../../plugins/i18n';

import useWindowDimensions from '../../hooks/useWindowDimensions';

function ProductCardPdfs({ pdfsList, color, size, checkLocale }) {
    const { isMobile } = useWindowDimensions();

    const [t] = useTranslation('ourProducts');

    const styleBySize = pdfItemStyle(size, isMobile);

    const lang = i18n.language;

    return (
        <div className={'my-2 pl-2'}>
            {pdfsList.map((pdf) => (
                <div
                    key={pdf.pdfTitle}
                    className={`${styleBySize.marginY} flex flex-row items-center`}
                >
                    <GoDotFill
                        size={styleBySize.dotSize}
                        color={color}
                        className={'mr-1 sm:mr-2'}
                    />

                    <a
                        href={checkLocale ? pdf.pdfLink[lang] : pdf.pdfLink}
                        target={'_blank'}
                        className={`${styleBySize.textSize} font-semibold underline hover:opacity-50`}
                        rel="noreferrer"
                        style={{ color }}
                    >
                        {t(pdf.pdfTitle)}
                    </a>
                </div>
            ))}
        </div>
    );
}

ProductCardPdfs.propTypes = {
    pdfsList: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'lg']),
    checkLocale: PropTypes.bool,
};

ProductCardPdfs.defaultProps = {
    pdfsList: [],
    color: Colors.primary_blue,
    size: 'sm',
    checkLocale: false,
};

export default ProductCardPdfs;
