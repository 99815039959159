import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function CardHeader({ valueKey, title }) {
    const [t] = useTranslation('ourProducts');

    return (
        <div className={'flex flex-row justify-between'}>
            <div className={'flex flex-row'}>
                <div className={'`text-center text-xl font-semibold text-indotech-primary_blue sm:text-3xl'}>{title || t(`products.${valueKey}.title`)}</div>
            </div>
        </div>
    );
}

CardHeader.propTypes = {
    valueKey: PropTypes.string,
    title: PropTypes.string,
};

CardHeader.defaultProps = {
    valueKey: '',
    title: '',
};

export default CardHeader;
