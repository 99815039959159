import React from 'react';
import PropTypes from 'prop-types';

function L5Separator({ classStyle }) {
    return <div className={`${classStyle ? classStyle : 'border-gray-300'} border`}></div>;
}

L5Separator.propTypes = {
    className: PropTypes.string,
};

L5Separator.defaultProps = {
    className: '',
};

export default L5Separator;
