import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DoorRadioBanner from './DoorRadioBanner';
import DoorRadioMoreInfo from './DoorRadioMoreInfo';

function DoorRadioComponent({
    value,
    label,
    labelSuffix,
    selectedOption,
    handleOptionChange,
    curtainOptions,
    selectedCurtain,
    setSelectedCurtain,
    technicalDetails,
}) {
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const radioChecked = selectedOption === value;

    return (
        <div className={`radio my-2 rounded-xl bg-indotech-light_gray`}>
            <label className={'flex cursor-pointer flex-col items-center'}>
                <input
                    type={'radio'}
                    value={value}
                    checked={radioChecked}
                    className={'peer h-0 w-0 appearance-none'}
                />

                <DoorRadioBanner
                    value={value}
                    label={label}
                    labelSuffix={labelSuffix}
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                    showMoreInfo={showMoreInfo}
                    setShowMoreInfo={setShowMoreInfo}
                />

                <DoorRadioMoreInfo
                    showMoreInfo={showMoreInfo}
                    curtainOptions={curtainOptions}
                    selectedCurtain={selectedCurtain}
                    setSelectedCurtain={setSelectedCurtain}
                    technicalDetails={technicalDetails}
                />
            </label>
        </div>
    );
}

DoorRadioComponent.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    labelSuffix: PropTypes.string,
    selectedOption: PropTypes.string,
    handleOptionChange: PropTypes.func,
    curtainOptions: PropTypes.object,
    selectedCurtain: PropTypes.string,
    setSelectedCurtain: PropTypes.func,
    technicalDetails: PropTypes.object,
};

DoorRadioComponent.defaultProps = {
    value: '',
    label: '',
    labelSuffix: '',
    selectedOption: '',
    handleOptionChange: () => {},
    curtainOptions: [],
    selectedCurtain: '',
    setSelectedCurtain: () => {},
    technicalDetails: [],
};

export default DoorRadioComponent;
