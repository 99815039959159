import React from 'react';
import PropTypes from 'prop-types';

function IndotechFormTitle({ title, classStyle, isSubtitle }) {
    return <div className={`${classStyle} ${isSubtitle ? 'text-base' : 'text-xl'} font-bold text-indotech-primary_blue`}>{title}</div>;
}

IndotechFormTitle.propTypes = {
    classStyle: PropTypes.string,
    title: PropTypes.string,
    isSubtitle: PropTypes.bool,
};

IndotechFormTitle.defaultProps = {
    classStyle: '',
    title: '',
    isSubtitle: false,
};

export default IndotechFormTitle;
