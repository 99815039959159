import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import IllustrationItemContent from './IllustrationItemContent';

function IllustrationsItem({ component, onClick }) {
    const { isMobile } = useWindowDimensions();

    const [showBackCard, setShowBackCard] = useState(false);

    function onClickItem() {
        if (component.notAvailable) {
            return;
        }

        if (isMobile) {
            setShowBackCard(true);

            return;
        }

        onClick(component);
    }

    return (
        <motion.div
            className={`w-full p-3 ${component.notAvailable ? '' : 'hover:cursor-pointer'} sm:h-1/3 sm:w-1/3`}
            style={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <IllustrationItemContent
                component={component}
                onClickItem={onClickItem}
                showBackCard={showBackCard}
                setShowBackCard={setShowBackCard}
            />
        </motion.div>
    );
}

IllustrationsItem.propTypes = {
    number: PropTypes.number,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

IllustrationsItem.defaultProps = {
    number: 0,
    title: '',
    onClick: () => {},
};

export default IllustrationsItem;
