import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const ElectricalPanels = {
    VARIABLE_SPEED: 'with variable speed drive',
    FUSED_DISCONNECT: 'with fused disconnect',
    BASE: 'basic',
    ECB: 'ECB',
};

function Electrical({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        { value: ElectricalPanels.VARIABLE_SPEED, label: t('sections.electrical.variableSpeedPanel') },
        { value: ElectricalPanels.FUSED_DISCONNECT, label: t('sections.electrical.fusedDisconnectPanel') },
        { value: ElectricalPanels.BASE, label: t('sections.electrical.basePanel') },
        { value: ElectricalPanels.ECB, label: t('sections.electrical.ecb') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.electrical.sectionTitle')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={false}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, electrical: e })}
                selected={form.electrical}
                itemContainerStyle={'my-2 sm:flex'}
            />
        </>
    );
}

Electrical.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

Electrical.defaultProps = {
    form: {},
    setForm: () => {},
};

export default Electrical;
