import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const Location = {
    RIGHT: 'Right side',
    LEFT: 'Left side',
};

function OperatorInstallation({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        { value: Location.LEFT, label: t('sections.operator.subSections.installation.left') },
        { value: Location.RIGHT, label: t('sections.operator.subSections.installation.right') },
    ];

    return (
        <div className={'mt-4'}>
            <IndotechFormTitle
                title={t('sections.operator.subSections.installation.title')}
                isSubtitle={true}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={true}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, operatorInstallation: e })}
                selected={form.operatorInstallation}
                itemContainerStyle={'my-2 w-80 sm:flex'}
            />
        </div>
    );
}

OperatorInstallation.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

OperatorInstallation.defaultProps = {
    form: {},
    setForm: () => {},
};

export default OperatorInstallation;
