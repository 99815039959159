import { useEffect, useState } from 'react';

export const useEmailValidation = (email) => {
    const [emailValid, setEmailValid] = useState(false);
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    useEffect(() => {
        setEmailValid(emailRegex.test(email));
    }, [email]);

    return emailValid;
};
