import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMail } from 'react-icons/io5';

import { Colors } from '../../utils/Colors';

import { contact } from '../../constant/contact';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ContactInformationItem from './ContactInformationItem';

function ContactInformationList() {
    const { isMobile } = useWindowDimensions();
    const [t] = useTranslation('contactUs');

    return (
        <div className={'w-full max-w-screen-xl sm:py-8'}>
            <div className={`sm:flex ${isMobile && 'px-4'}`}>
                <ContactInformationItem
                    icon={
                        <FaPhoneAlt
                            size={20}
                            color={Colors.primary_blue}
                        />
                    }
                    title={t('phone')}
                    value={contact.PHONE.value}
                    secondValueTitle={`${t('free')}:`}
                    secondValue={contact.PHONE.secondValue}
                    href={isMobile && `tel:${contact.PHONE.value}`}
                    secondHref={isMobile && `tel:${contact.PHONE.secondValue}`}
                />

                <ContactInformationItem
                    icon={
                        <FaLocationDot
                            size={20}
                            color={Colors.primary_blue}
                        />
                    }
                    title={t('address')}
                    value={contact.ADDRESS.value}
                    href={`https://google.ca/maps/search/${contact.ADDRESS.value}`}
                />

                <ContactInformationItem
                    icon={
                        <IoMail
                            size={20}
                            color={Colors.primary_blue}
                        />
                    }
                    title={t('email')}
                    value={contact.EMAIL.value}
                    href={`mailTo:${contact.EMAIL.value}`}
                />
            </div>
        </div>
    );
}

export default ContactInformationList;
