import React from 'react';
import PropTypes from 'prop-types';

import { L5RadioButtonSize } from '../../../enums/L5RadioButtonSize';

import L5RadioButtonList from './L5RadioButtonList';

function L5RadioGroup({ selected, setSelected, options, horizontal, formStyle, color, size, itemContainerStyle, textStyle }) {
    return (
        <form className={`flex ${horizontal ? 'flex-row' : 'flex-col'} ${formStyle}`}>
            <L5RadioButtonList
                options={options}
                color={color}
                size={size}
                itemContainerStyle={itemContainerStyle}
                textStyle={textStyle}
                selected={selected}
                setSelected={setSelected}
            />
        </form>
    );
}

L5RadioGroup.propTypes = {
    options: PropTypes.object,
    horizontal: PropTypes.bool,
    formStyle: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.oneOf([L5RadioButtonSize.LG, L5RadioButtonSize.MD, L5RadioButtonSize.SM]),
    itemContainerStyle: PropTypes.string,
    textStyle: PropTypes.string,
};

L5RadioGroup.defaultProps = {
    options: [],
    horizontal: false,
    formStyle: '',
    color: '#000000',
    size: L5RadioButtonSize.MD,
    itemContainerStyle: '',
    textStyle: '',
};

export default L5RadioGroup;
