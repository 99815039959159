import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import IndotechMap from './IndotechMap';
import InformationField from './InformationField';

function Information({ address, contact, webPage, coordinates }) {
    const [t] = useTranslation('ourDistributors');
    const { isMobile } = useWindowDimensions();

    const mobileStyle = 'flex flex-col p-8';
    const browserStyle = 'flex min-h-[650px] flex-1 flex-col justify-center pl-16';

    const onClickWebsite = () => {
        window.open(`https://${webPage}`, '_blank');
    };

    const onClickAddress = () => {
        window.open(`https://google.ca/maps/search/${address}`, '_blank');
    };

    return (
        <div className={`flex w-full max-w-screen-xl flex-1 flex-col-reverse pb-10 sm:flex-row`}>
            <div className={`${isMobile ? mobileStyle : browserStyle}`}>
                <InformationField
                    label={t('address')}
                    value={address}
                    valueStyle={'hover:cursor-pointer hover:text-opacity-50'}
                    onValueClick={onClickAddress}
                />

                <InformationField
                    label={t('contact')}
                    value={contact}
                    className={'mb-12 mt-12 sm:mb-20 sm:mt-20'}
                />

                <InformationField
                    label={t('web_page')}
                    value={webPage}
                    valueStyle={'hover:cursor-pointer hover:text-opacity-50'}
                    onValueClick={onClickWebsite}
                />
            </div>

            <IndotechMap coordinates={coordinates} />
        </div>
    );
}

Information.propTypes = {
    address: PropTypes.string,
    contact: PropTypes.string,
    webPage: PropTypes.string,
    coordinates: PropTypes.object,
};

Information.defaultProps = {
    address: '',
    contact: '',
    webPage: '',
    coordinates: {
        lat: 0,
        lng: 0,
    },
};

export default Information;
