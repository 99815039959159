import { Members } from '../enums/TeamMember';

import bouchra from '../assets/pictures/Bouchra.jpg';
import karen from '../assets/pictures/Karen.jpg';

export const teamMembers = [
    {
        key: 'bouchra',
        name: Members.BOUCHRA,
        position: 'members.bouchra.position',
        text: 'members.bouchra.text',
        picture: bouchra,
    },
    {
        key: 'karen',
        name: Members.KAREN,
        position: 'members.karen.position',
        text: 'members.karen.text',
        picture: karen,
    },
];
