import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const LINKED_IN_URL = 'https://www.linkedin.com/company/les-portes-industrielles-indotech-inc/';

function FooterMediaLinks() {
    const { isMobile } = useWindowDimensions();

    const onClickLinkedIn = () => {
        window.open(LINKED_IN_URL, '_blank');
    };

    return (
        <div className={'ml-3 flex flex-row items-center sm:ml-6'}>
            <FaLinkedin
                className={'hover:cursor-pointer hover:opacity-50'}
                color={'white'}
                size={isMobile ? 20 : 40}
                onClick={onClickLinkedIn}
            />
        </div>
    );
}

export default FooterMediaLinks;
