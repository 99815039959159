import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIGHT } from '../../../constant/navbarTheme';
import { products } from '../../../constant/products/products';
import NavLink from '../NavLink';
import DropDownMenu from './DropDownMenu';

function ProductsDropDownMenu({ theme, active }) {
    const [t] = useTranslation(['navbar', 'ourProducts']);

    const location = useLocation();

    return (
        <DropDownMenu
            title={t('products')}
            theme={theme}
            titleActive={active}
        >
            <>
                {products.map((product) => {
                    return (
                        <NavLink
                            key={product.titleKey}
                            containerStyle={`${!product.isLastItem ? 'border-b' : ''} border-indotech-orange`}
                            title={t('tech-roll', { ns: 'ourProducts' })}
                            secondTitle={t(`product.${product.titleKey}`)}
                            secondTextColor={'text-indotech-orange'}
                            theme={theme}
                            linkTo={product.linkTo}
                            active={location.pathname === product.linkTo}
                        />
                    );
                })}
            </>
        </DropDownMenu>
    );
}

ProductsDropDownMenu.propTypes = {
    theme: PropTypes.string,
    active: PropTypes.bool,
};

ProductsDropDownMenu.defaultProps = {
    theme: LIGHT,
    active: false,
};

export default ProductsDropDownMenu;
