import React from 'react';
import { useTranslation } from 'react-i18next';

import { companyValues } from '../../constant/companyValues';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SectionTitle from '../ui-elements/SectionTitle';
import ValuesCard from './ValuesCard';

function OurValues() {
    const [t] = useTranslation('aboutUs');
    const { height, width } = useWindowDimensions();

    function getMobilePaddingTop() {
        if (height >= 900) {
            return 'pt-[350px]';
        }

        if (height >= 800) {
            return 'pt-[320px]';
        }

        if (height >= 700) {
            return 'pt-[370px]';
        }

        return 'pt-[300px]';
    }

    function getBrowserDynamicPadding() {
        const heightWidthRatio = height / width;

        if (heightWidthRatio >= 0.9) {
            return 'sm:pt-[450px]';
        }

        if (heightWidthRatio >= 0.8) {
            return 'sm:pt-[400px]';
        }

        if (heightWidthRatio >= 0.7) {
            return 'sm:pt-[350px]';
        }

        if (heightWidthRatio >= 0.6) {
            return 'sm:pt-[300px]';
        }

        if (heightWidthRatio >= 0.5) {
            return 'sm:pt-[250px]';
        }

        return 'sm:pt-[250px]';
    }

    return (
        <div className={`max-w-screen-xl px-4 ${getMobilePaddingTop()} sm:py-32 ${getBrowserDynamicPadding()}`}>
            <SectionTitle
                title={t('our_values.title').toUpperCase()}
                classStyle={'p-8 text-white'}
            />

            <div className={'flex flex-wrap justify-center p-8'}>
                {companyValues.map((companyValue) => (
                    <ValuesCard
                        key={companyValue.valueKey}
                        title={companyValue.title}
                        description={companyValue.description}
                        logo={companyValue.logo}
                    />
                ))}
            </div>
        </div>
    );
}

export default OurValues;
