import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const Tension = {
    LOW: '208V',
    MED: '460V',
    HIGH: '575V',
};

function OperatorTension({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        { value: Tension.LOW, label: '208V' },
        { value: Tension.MED, label: '460V' },
        { value: Tension.HIGH, label: '575V' },
    ];

    return (
        <div className={'mt-4'}>
            <IndotechFormTitle
                title={t('sections.operator.subSections.voltage')}
                isSubtitle={true}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={true}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, operatorTension: e })}
                selected={form.operatorTension}
                itemContainerStyle={'my-2 w-80 sm:flex'}
            />
        </div>
    );
}

OperatorTension.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

OperatorTension.defaultProps = {
    form: {},
    setForm: () => {},
};

export default OperatorTension;
