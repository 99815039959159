import React from 'react';
import { useTranslation } from 'react-i18next';

import { IndotechButtonSizes } from '../../enums/IndotechButtonSizes';

import IndotechLinkButton from './IndotechLinkButton';

import { CONTACT, QUOTE } from '../../constant/navigation/routeNames';

function QuoteAndContactButtons() {
    const [t] = useTranslation(['intro', 'contactUs']);

    return (
        <div className={'flex w-full max-w-screen-xl flex-col'}>
            <div className={'mx-10 flex flex-col sm:mx-32 sm:flex-row sm:justify-between'}>
                <IndotechLinkButton
                    title={t('quote', { ns: 'intro' })}
                    containerStyle={'my-2 sm:my-0 sm:w-1/3'}
                    bgColor={'bg-indotech-secondary_blue'}
                    textStyle={'py-3'}
                    size={IndotechButtonSizes.MD}
                    linkTo={QUOTE}
                />

                <IndotechLinkButton
                    title={t('contact_us', { ns: 'contactUs' })}
                    containerStyle={'my-2 sm:my-0 sm:w-1/3'}
                    textStyle={'py-3'}
                    size={IndotechButtonSizes.MD}
                    linkTo={CONTACT}
                />
            </div>
        </div>
    );
}

export default QuoteAndContactButtons;
