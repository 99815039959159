import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useWindowDimensions from './useWindowDimensions';

export default function useScrollToTop() {
    const { pathname } = useLocation();

    const { isMobile } = useWindowDimensions();

    useEffect(() => {
        if (isMobile) {
            window.scrollTo(0, 0);
        } else {
            // The smooth behavior just breaks it on mobile
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [pathname]);
}
