import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const DoorLocation = {
    INTERIOR: 'interior',
    EXTERIOR: 'exterior',
};

function DoorInstallation({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        { value: DoorLocation.INTERIOR, label: t('sections.installation.interior') },
        { value: DoorLocation.EXTERIOR, label: t('sections.installation.exterior') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.installation.sectionTitle')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={false}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, doorInstallation: e })}
                selected={form.doorInstallation}
                itemContainerStyle={'my-2 sm:flex'}
            />
        </>
    );
}

DoorInstallation.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

DoorInstallation.defaultProps = {
    form: {},
    setForm: () => {},
};

export default DoorInstallation;
