import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

import useWindowDimensions from '../../hooks/useWindowDimensions';

function TechBanner({ productKey, imageSrc, onClickTechnicalInformation }) {
    const { isMobile } = useWindowDimensions();

    const [t] = useTranslation('ourProducts');

    return (
        <div className={'flex w-full max-w-screen-xl flex-row'}>
            <div className={'flex-1 pb-0 sm:basis-2/5 sm:pr-4 sm:pt-2'}>
                <div className={'my-2 text-5xl font-semibold text-indotech-primary_blue sm:text-6xl'}>{t('tech-roll')}</div>

                <div className={'my-2 text-5xl font-semibold text-indotech-orange sm:text-6xl'}>{t(`products.${productKey}.title`)}</div>

                <div className={'my-6 text-justify text-xl text-indotech-primary_blue sm:my-8 sm:text-2xl'}>{t(`products.${productKey}.description`)}</div>

                <div
                    className={'flex flex-row items-center hover:cursor-pointer hover:opacity-50'}
                    onClick={onClickTechnicalInformation}
                >
                    <IoIosInformationCircle
                        size={isMobile ? 23 : 30}
                        className={'mr-1 sm:mr-2'}
                        color={Colors.primary_blue}
                    />

                    <div className={'text-indotech-primary_blue underline sm:text-lg'}>{t('technicalInformation')}</div>
                </div>
            </div>

            {!isMobile && (
                <div className={'-mb-6 -mr-12 flex items-end sm:basis-3/5'}>
                    <img
                        src={imageSrc}
                        alt={'product image'}
                        className={'w-full'}
                    />
                </div>
            )}
        </div>
    );
}

TechBanner.propTypes = {
    productKey: PropTypes.string,
    imageSrc: PropTypes.string,
    onClickTechnicalInformation: PropTypes.func,
};

TechBanner.defaultProps = {
    productKey: '',
    imageSrc: '',
    onClickTechnicalInformation: () => {},
};

export default TechBanner;
