import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@mui/material';

import { Colors } from '../../../utils/Colors';
import { getModalBgColor } from '../../../utils/styles';

import { LIGHT } from '../../../constant/navbarTheme';

function IndotechDrawer({ toggled, setToggled, anchor, paperProps, theme, children }) {
    return (
        <Drawer
            open={toggled}
            onClose={() => setToggled(false)}
            anchor={anchor}
            elevation={0}
            ModalProps={{
                slotProps: {
                    backdrop: { sx: { background: 'none' } },
                },
            }}
            PaperProps={{
                sx: {
                    height: 'calc(100% - 75px)',
                    top: 75,
                    borderTop: 1,
                    borderColor: Colors.orange,
                    backgroundColor: getModalBgColor(theme, true),
                    ...paperProps,
                },
            }}
        >
            {children}
        </Drawer>
    );
}

IndotechDrawer.propTypes = {
    toggled: PropTypes.bool,
    setToggled: PropTypes.func,
    anchor: PropTypes.string,
    paperProps: PropTypes.object,
    theme: PropTypes.string,
    children: PropTypes.element.isRequired,
};

IndotechDrawer.defaultProps = {
    toggled: false,
    setToggled: () => {},
    paperProps: {},
    theme: LIGHT,
    anchor: 'bottom',
};

export default IndotechDrawer;
