import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ProductCardPdfs from '../our-products/ProductCardPdfs';
import SectionTitle from '../ui-elements/SectionTitle';

function TechTechnicalInfo(props, ref) {
    const [t] = useTranslation('ourProducts');

    const { pdfsList, valueKey } = props;

    return (
        <div
            className={'flex w-full max-w-screen-xl flex-col'}
            ref={ref}
        >
            <SectionTitle
                title={t(`technicalInformation`)}
                classStyle={'text-white'}
            />

            <ProductCardPdfs
                pdfsList={pdfsList}
                valueKey={valueKey}
                checkLocale={true}
                color={'white'}
                size={'lg'}
            />
        </div>
    );
}

TechTechnicalInfo.propTypes = {
    pdfsList: PropTypes.object,
    valueKey: PropTypes.string,
};

TechTechnicalInfo.defaultProps = {
    pdfsList: [],
    valueKey: '',
};

export default forwardRef(TechTechnicalInfo);
