import React from 'react';
import { useTranslation } from 'react-i18next';

import { IndotechButtonSizes } from '../../enums/IndotechButtonSizes';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import IndotechLinkButton from '../ui-elements/IndotechLinkButton';
import SectionTitle from '../ui-elements/SectionTitle';

import { QUOTE } from '../../constant/navigation/routeNames';

function Welcome() {
    const [t] = useTranslation('intro');

    const { isMobile } = useWindowDimensions();

    return (
        <div className={'absolute bottom-0 flex w-full flex-row items-end justify-between pr-4'}>
            <div className={'mx-6 my-8 basis-2/5 sm:basis-7/12'}>
                <SectionTitle
                    title={t('welcome')}
                    classStyle={'my-6 text-base text-indotech-orange sm:mx-4 sm:text-5xl'}
                />

                <div className={'mt-4 text-white sm:mx-4 sm:my-4 sm:text-4xl'}>{t('welcome_text')}</div>
            </div>

            <div className={'my-8 -mr-1 sm:mx-4'}>
                <IndotechLinkButton
                    title={t('quote')}
                    bgColor={'bg-indotech-orange'}
                    textColor={'text-indotech-primary_blue'}
                    textStyle={'px-14'}
                    size={isMobile ? IndotechButtonSizes.SM : IndotechButtonSizes.LG}
                    linkTo={QUOTE}
                />
            </div>
        </div>
    );
}

export default Welcome;
