import React from 'react';

import Footer from '../components/footer/Footer.jsx';
import OurDistributors from '../components/home/OurDistributors.jsx';
import Welcome from '../components/home/Welcome.jsx';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import Section from '../components/ui-elements/Section.jsx';

import bgImage from '../assets/pictures/door_STMStinson_zoomed.JPG';

export default function LandingPage() {
    return (
        <AnimatedPage>
            <Section classStyle={`h-screen drop-shadow-lg`}>
                <>
                    <img
                        src={bgImage}
                        className={'w-screen bg-no-repeat object-cover opacity-90 brightness-25'}
                        alt={'background image'}
                    />

                    <Welcome />
                </>
            </Section>

            <Section classStyle={'mb-10'}>
                <OurDistributors />
            </Section>

            <Footer />
        </AnimatedPage>
    );
}
