import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const ExpeditionOptions = {
    CARDBOARD: 'Cardboard',
    PALETTE: 'Palette',
    CRATE: 'Crate',
};

function Expedition({ form, setForm }) {
    const [t] = useTranslation('quote');

    const { isMobile } = useWindowDimensions();

    const options = [
        { value: ExpeditionOptions.CARDBOARD, label: t('sections.expedition.cardboard') },
        { value: ExpeditionOptions.PALETTE, label: t('sections.expedition.palette') },
        { value: ExpeditionOptions.CRATE, label: t('sections.expedition.crate') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.expedition.sectionTitle')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={!isMobile}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, expedition: e })}
                selected={form.expedition}
                itemContainerStyle={'my-2 sm:flex sm:w-80'}
            />
        </>
    );
}

Expedition.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

Expedition.defaultProps = {
    form: {},
    setForm: () => {},
};

export default Expedition;
