import React from 'react';
import PropTypes from 'prop-types';

import IllustrationsItem from './IllustrationItem';

function IllustrationsMappingBrowser({ components, onItemClick }) {
    return components.map((component) => {
        return (
            <IllustrationsItem
                key={component.id}
                component={component}
                onClick={(component) => onItemClick(component)}
            />
        );
    });
}

IllustrationsMappingBrowser.propTypes = {
    components: PropTypes.object,
};

IllustrationsMappingBrowser.defaultProps = {
    components: [],
};

export default IllustrationsMappingBrowser;
