import React from 'react';
import PropTypes from 'prop-types';

import Banner from '../components/distributors/Banner';
import Information from '../components/distributors/Information';
import Footer from '../components/footer/Footer';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import Section from '../components/ui-elements/Section';

function Distributor({ distributor }) {
    const { logo, title, address, contact, webPage, coordinates } = distributor;

    return (
        <AnimatedPage className={'min-h-screen'}>
            <Section>
                <Banner
                    logo={logo}
                    title={title}
                />
            </Section>

            <Section classStyle={'bg-indotech-primary_blue'}>
                <Information
                    address={address}
                    contact={contact}
                    webPage={webPage}
                    coordinates={coordinates}
                />
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

Distributor.propTypes = {
    distributor: PropTypes.object,
};

Distributor.defaultProps = {
    distributor: {
        key: '',
        logo: '',
        title: {
            fullName: '',
            firstPart: '',
            highlightedPart: '',
        },
        linkTo: '',
        description: '',
        address: '',
        contact: '',
        webPage: '',
        coordinates: {
            lat: 0,
            lng: 0,
        },
    },
};

export default Distributor;
