import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useEmailValidation } from '../../../hooks/useEmailValidation';
import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import IndotechTextInput from '../../ui-elements/IndotechTextInput';

function ContactInformation({ form, setForm }) {
    const [t] = useTranslation('contactUs');

    const isValidEmail = useEmailValidation(form.email);

    return (
        <>
            <IndotechFormTitle title={t('contact_information')} />

            <div className={`mt-4 sm:flex`}>
                <IndotechTextInput
                    label={t('first_name')}
                    type={'text'}
                    autocomplete={'given-name'}
                    required={true}
                    onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                    withMarginRight={true}
                    value={form.firstName}
                />

                <IndotechTextInput
                    label={t('last_name')}
                    type={'text'}
                    autocomplete={'family-name'}
                    required={true}
                    onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                    value={form.lastName}
                />
            </div>

            <div className={`sm:flex`}>
                <IndotechTextInput
                    label={t('business_name')}
                    type={'text'}
                    autocomplete={'organization'}
                    required={true}
                    onChange={(e) => setForm({ ...form, businessName: e.target.value })}
                    withMarginRight={true}
                    value={form.businessName}
                />

                <IndotechTextInput
                    label={t('job_title')}
                    type={'text'}
                    onChange={(e) => setForm({ ...form, jobTitle: e.target.value })}
                    value={form.jobTitle}
                />
            </div>

            <div className={`sm:flex`}>
                <IndotechTextInput
                    label={t('email')}
                    type={'email'}
                    autocomplete={'email'}
                    required={true}
                    isIncorrect={!isValidEmail && form.email !== ''}
                    incorrectWarningText={t('email_not_valid')}
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                    withMarginRight={true}
                    value={form.email}
                />

                <IndotechTextInput
                    label={t('phone')}
                    type={'tel'}
                    required={true}
                    autocomplete={'tel'}
                    onChange={(e) => setForm({ ...form, phone: e.target.value })}
                    value={form.phone}
                />
            </div>
        </>
    );
}

ContactInformation.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

ContactInformation.defaultProps = {
    form: {},
    setForm: () => {},
};

export default ContactInformation;
