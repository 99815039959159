import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../ui-elements/SectionTitle';

function OurHistory() {
    const [t] = useTranslation('aboutUs');

    return (
        <div className={'mt-10 flex w-4/5 flex-col items-center sm:w-3/5'}>
            <SectionTitle
                title={t('our_history').toUpperCase()}
                classStyle={'text-center text-indotech-orange'}
            />

            <div className={'my-5 max-w-screen-sm rounded bg-white p-4 px-10 sm:my-10'}>
                <div className={'my-4 text-sm font-semibold text-indotech-primary_blue sm:text-xl'}>{t('our_history_text_1')}</div>

                <div className={'my-4 whitespace-pre-wrap text-justify text-sm text-indotech-primary_blue sm:text-xl'}>{t('our_history_text_2')}</div>
            </div>
        </div>
    );
}

export default OurHistory;
