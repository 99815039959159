import React, { useRef } from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

import Footer from '../components/footer/Footer';
import TechBanner from '../components/TechRoll/TechBanner';
import TechIllustrations from '../components/TechRoll/TechIllustrations';
import TechSpecifications from '../components/TechRoll/TechSpecifications';
import TechTechnicalInfo from '../components/TechRoll/TechTechnicalInfo';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import QuoteAndContactButtons from '../components/ui-elements/QuoteAndContactButtons';
import Section from '../components/ui-elements/Section';

import { products } from '../constant/products/products';
import useWindowDimensions from '../hooks/useWindowDimensions';

function TechProduct({ productKey }) {
    const { isMobile, width } = useWindowDimensions();
    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const product = products.find((product) => product.titleKey === productKey);

    return (
        <AnimatedPage>
            <Section classStyle={'px-12 pb-6 pt-4 sm:pt-24'}>
                <TechBanner
                    productKey={product.titleKey}
                    imageSrc={product.bannerImage}
                    onClickTechnicalInformation={handleClick}
                />
            </Section>

            {/*Was asked to remove, if we need it again we can uncomment this*/}
            {/*<Section classStyle={'bg-indotech-primary_blue px-12 pb-12 pt-10'}>*/}
            {/*    <TechInformations infoKeys={product.infoKeys} />*/}
            {/*</Section>*/}

            {/*Was asked to remove, if we need it again we can uncomment this*/}
            {/*<Section classStyle={'px-12 pb-6 pt-10'}>*/}
            {/*    <TechBenefits benefits={product.benefits} />*/}
            {/*</Section>*/}

            <YouTube
                videoId={product.videoId}
                id={product.videoId}
                title={product.titleKey.toUpperCase()}
                opts={{
                    width: width,
                    height: width / 2.5,
                }}
            />

            {!isMobile && (
                <Section classStyle={'px-12 pb-12 pt-10 sm:pb-20'}>
                    <TechSpecifications
                        productKey={product.titleKey}
                        specificationKeys={product.specificationKeys}
                    />
                </Section>
            )}

            <Section classStyle={'py-6 sm:py-0 sm:pb-20'}>
                <QuoteAndContactButtons />
            </Section>

            {product.pdfs?.length > 0 && (
                <Section classStyle={'bg-indotech-primary_blue px-12 py-6'}>
                    <TechTechnicalInfo
                        ref={ref}
                        pdfsList={product.pdfs}
                        valueKey={product.titleKey}
                    />
                </Section>
            )}

            <Section classStyle={'bg-indotech-light_gray px-12 pb-12 pt-10 sm:pb-20'}>
                <TechIllustrations components={product.components} />
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

TechProduct.propTypes = {
    productKey: PropTypes.string,
};

TechProduct.defaultProps = {
    productKey: '',
};

export default TechProduct;
