import React from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

import IllustrationNumber from './IllustrationNumber';

function IllustrationMoreInfoBanner({ number, title, onClose }) {
    return (
        <div className={'mb-4 flex w-full flex-row items-center justify-between'}>
            <div className={'flex flex-row items-center'}>
                <IllustrationNumber number={number} />

                <div className={'ml-4 text-center text-lg font-semibold text-indotech-primary_blue sm:text-2xl'}>{title}</div>
            </div>

            <IoMdCloseCircleOutline
                size={35}
                className={'hover:cursor-pointer hover:opacity-50'}
                color={Colors.primary_blue}
                onClick={onClose}
            />
        </div>
    );
}

IllustrationMoreInfoBanner.propTypes = {
    number: PropTypes.number,
    title: PropTypes.string,
    onClose: PropTypes.func,
};

IllustrationMoreInfoBanner.defaultProps = {
    number: 0,
    title: '',
    onClose: () => {},
};

export default IllustrationMoreInfoBanner;
