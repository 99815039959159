import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SectionTitle from '../ui-elements/SectionTitle';
import SpecificationsList from './SpecificationsList';

function TechSpecifications({ productKey, specificationKeys }) {
    const [t] = useTranslation('ourProducts');

    return (
        <div className={'flex w-full max-w-screen-xl flex-col'}>
            <SectionTitle
                title={t(`specifications`)}
                classStyle={'text-indotech-primary_blue sm:mb-10'}
            />

            <div className={'mt-4 bg-indotech-secondary_blue px-4 py-6 text-lg font-bold text-indotech-primary_blue sm:px-10 sm:text-xl'}>
                {t('tech-roll').concat(' ' + t(`products.${productKey}.title`))}
            </div>

            <SpecificationsList specificationKeys={specificationKeys} />
        </div>
    );
}

TechSpecifications.propTypes = {
    productKey: PropTypes.string,
    specificationKeys: PropTypes.object,
};

TechSpecifications.defaultProps = {
    productKey: '',
    specificationKeys: [],
};

export default TechSpecifications;
