import React from 'react';
import PropTypes from 'prop-types';

import Card from '../ui-elements/Card';
import ProductCardContent from './ProductCardContent';
import ProductCardFooter from './ProductCardFooter';
import ProductCardHeader from './ProductCardHeader';

function ProductCard({ index, valueKey, linkTo, pdfsList, images }) {
    return (
        <Card
            index={index}
            header={
                <ProductCardHeader
                    valueKey={valueKey}
                    linkTo={linkTo}
                    images={images}
                />
            }
            content={
                <ProductCardContent
                    valueKey={valueKey}
                    pdfsList={pdfsList}
                />
            }
            footer={<ProductCardFooter linkTo={linkTo} />}
        />
    );
}

ProductCard.propTypes = {
    index: PropTypes.number,
    valueKey: PropTypes.string,
    linkTo: PropTypes.string,
    pdfsList: PropTypes.object,
    images: PropTypes.object,
};

ProductCard.defaultProps = {
    index: 0,
    valueKey: '',
    linkTo: '',
    pdfsList: [],
    images: [],
};

export default ProductCard;
