import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IllustrationMoreInfoBanner from './IllustrationMoreInfoBanner';
import IllustrationNumber from './IllustrationNumber';

function IllustrationItemContent({ component, onClickItem, showBackCard, setShowBackCard }) {
    const [t] = useTranslation('ourProducts');

    if (showBackCard) {
        return (
            <div className={'flex h-full w-full flex-col items-center rounded-2xl bg-white p-4 sm:p-2'}>
                <IllustrationMoreInfoBanner
                    number={component.number}
                    title={t(component.title)}
                    onClose={() => setShowBackCard(false)}
                />

                <div className={'flex text-justify text-sm font-semibold font-semibold text-indotech-primary_blue sm:ml-4'}>{t(component.description)}</div>
            </div>
        );
    }

    return (
        <div
            className={`flex h-full w-full flex-col items-center justify-center rounded-2xl ${component.notAvailable ? 'opacity-50' : ''} bg-white p-2 sm:hover:opacity-50`}
            onClick={onClickItem}
        >
            <IllustrationNumber number={component.number} />

            <div className={'my-2 text-center font-semibold text-indotech-primary_blue'}>{t(component.title)}</div>

            {component.notAvailable && <div className={'my-2 text-center font-semibold text-indotech-primary_blue sm:-my-2'}>{t('notAvailable')}</div>}

            {component.isOptional && <div className={'my-2 text-center font-semibold text-indotech-primary_blue sm:-my-2'}>{t('isOptional')}</div>}
        </div>
    );
}

IllustrationItemContent.propTypes = {
    component: PropTypes.object,
    onClickItem: PropTypes.func,
    showBackCard: PropTypes.bool,
    setShowBackCard: PropTypes.func,
};

IllustrationItemContent.defaultProps = {
    component: {},
    onClickItem: () => {},
    showBackCard: false,
    setShowBackCard: () => {},
};

export default IllustrationItemContent;
