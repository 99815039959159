import React from 'react';

import FooterContactInfo from './FooterContactInfo';
import FooterMediaLinks from './FooterMediaLinks';

function Footer() {
    return (
        <div className={'flex w-full flex-row items-center justify-between border-t-2 border-white bg-indotech-primary_blue p-3 sm:px-5'}>
            <div className={'text-2xs text-white sm:text-lg'}>@Indotech 2004-2024</div>

            <div className={'flex flex-row items-center'}>
                <FooterContactInfo />

                <div className={'h-6 border-spacing-px border-l border-white sm:h-10'} />

                <FooterMediaLinks />
            </div>
        </div>
    );
}

export default Footer;
