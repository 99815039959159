import React from 'react';
import { useTranslation } from 'react-i18next';

import flyerPdf_EN from '../../assets/pdfDocuments/EN/Flyer INDOTECH EN.pdf';
import flyerPdf_FR from '../../assets/pdfDocuments/FR/Flyer INDOTECH FR.pdf';
import { products } from '../../constant/products/products';
import Card from '../ui-elements/Card';
import CardHeader from '../ui-elements/CardHeader';
import SectionTitle from '../ui-elements/SectionTitle';
import ProductCard from './ProductCard';
import ProductCardPdfs from './ProductCardPdfs';

function OurProductsList() {
    const [t] = useTranslation('ourProducts');

    const flyerPdfs = [
        { pdfTitle: t('flyer_fr'), pdfLink: flyerPdf_FR },
        { pdfTitle: t('flyer_en'), pdfLink: flyerPdf_EN },
    ];

    return (
        <div className={'w-full max-w-screen-xl'}>
            <SectionTitle
                title={t('technicalInformation')}
                classStyle={'mb-6 mt-4 text-indotech-primary_blue'}
            />

            <div className={'flex flex-wrap justify-start'}>
                {products.map((product, index) => {
                    return (
                        <ProductCard
                            index={index}
                            key={product.titleKey}
                            valueKey={product.titleKey}
                            linkTo={product.linkTo}
                            pdfsList={product.pdfs}
                            images={product.images}
                        />
                    );
                })}

                <Card
                    index={products.length}
                    header={<CardHeader title={t('flyer')} />}
                    content={<ProductCardPdfs pdfsList={flyerPdfs} />}
                />
            </div>
        </div>
    );
}

OurProductsList.propTypes = {};

OurProductsList.defaultProps = {};

export default OurProductsList;
