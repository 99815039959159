import React from 'react';
import { useTranslation } from 'react-i18next';

import { IndotechButtonSizes } from '../../enums/IndotechButtonSizes';

import { teamMembers } from '../../constant/teamMembers';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import IndotechLinkButton from '../ui-elements/IndotechLinkButton';
import SectionTitle from '../ui-elements/SectionTitle';
import TeamMemberCard from './TeamMemberCard';

import { CONTACT } from '../../constant/navigation/routeNames';

function OurTeam() {
    const [t] = useTranslation(['ourTeam', 'contactUs']);

    const { isMobile } = useWindowDimensions();

    return (
        <div className={'w-full max-w-screen-xl bg-white px-4 pt-16'}>
            <SectionTitle
                title={t('our_team').toUpperCase()}
                classStyle={'p-8 text-indotech-primary_blue'}
            />

            <div className={'mt-6 flex flex-wrap justify-center px-8'}>
                {teamMembers.map((member) => (
                    <TeamMemberCard
                        key={member.key}
                        name={member.name}
                        position={member.position}
                        text={member.text}
                        picture={member.picture}
                    />
                ))}
            </div>

            <div className={'my-8 flex justify-center'}>
                <IndotechLinkButton
                    title={t('contact_us', { ns: 'contactUs' }).toUpperCase()}
                    containerStyle={'w-2/3 sm:w-1/2'}
                    size={isMobile ? IndotechButtonSizes.SM : IndotechButtonSizes.XL}
                    linkTo={CONTACT}
                />
            </div>
        </div>
    );
}

export default OurTeam;
