import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { APIProvider } from '@vis.gl/react-google-maps';
import { AnimatePresence } from 'framer-motion';

import Navbar from './components/navigation/Navbar';

import { DARK, LIGHT } from './constant/navbarTheme';
import useScrollToTop from './hooks/useScrollToTop';

import { distributorPages, mainPages, productPages } from './constant/navigation/routeHelpers.js';
import { ABOUT, HOME, REALISATIONS, ROOT } from './constant/navigation/routeNames';

const allPages = mainPages.concat(distributorPages, productPages);

export default function App() {
    const location = useLocation();

    const navbarTheme = () => {
        const path = location.pathname;

        if (path === HOME || path === ABOUT || path === REALISATIONS || path === ROOT) {
            return DARK;
        }

        return LIGHT;
    };

    useScrollToTop();

    return (
        <>
            <Navbar theme={navbarTheme()} />
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <AnimatePresence mode={'wait'}>
                    <Routes
                        key={location.pathname}
                        location={location}
                    >
                        <>
                            {allPages.map((page) => (
                                <Route
                                    key={page.path}
                                    path={page.path}
                                    element={page.element}
                                />
                            ))}
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to="/"
                                        replace={true}
                                    />
                                }
                            />
                        </>
                    </Routes>
                </AnimatePresence>
                <ToastContainer />
            </APIProvider>
        </>
    );
}
