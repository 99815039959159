import React from 'react';
import PropTypes from 'prop-types';

import DoorRadioComponentList from './DoorRadioComponentList';

function DoorRadioGroup({ selected, setSelected, selectedCurtain, setSelectedCurtain, options }) {
    return (
        <form className={'flex flex-col'}>
            <DoorRadioComponentList
                selected={selected}
                setSelected={setSelected}
                setSelectedCurtain={setSelectedCurtain}
                selectedCurtain={selectedCurtain}
                options={options}
            />
        </form>
    );
}

DoorRadioGroup.propTypes = {
    selected: PropTypes.string,
    setSelected: PropTypes.func,
    selectedCurtain: PropTypes.string,
    setSelectedCurtain: PropTypes.func,
    options: PropTypes.object,
};

DoorRadioGroup.defaultProps = {
    selected: '',
    setSelected: () => {},
    selectedCurtain: '',
    setSelectedCurtain: () => {},
    options: [],
};

export default DoorRadioGroup;
