import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DARK, LIGHT } from '../../constant/navbarTheme.js';
import useLanguage from '../../hooks/useLanguage';
import useScrollStyle from '../../hooks/useScrollStyle';
import IndotechLinkButton from '../ui-elements/IndotechLinkButton';
import DistributorsDropDownMenu from './DropDownMenus/DistributorsDropDownMenu';
import ProductsDropDownMenu from './DropDownMenus/ProductsDropDownMenu';
import NavLink from './NavLink';
import NavLogoLink from './NavLogoLink';

import {
    ABOUT,
    ANDRE_INDUSTRIES,
    APEX,
    CFM_GROUP,
    CONTACT,
    DEK,
    DRAKKAR,
    HOME,
    LG_DOORS,
    LONGPRE_DOORS,
    QUOTE,
    REALISATIONS,
    SIMS,
    TECH_INFO,
    TECH_ROLL_LHR,
    TECH_ROLL_LITE,
    TECH_ROLL_MEGA,
    TECH_ROLL_PRO,
    TECH_ROLL_ULTRA,
    UNIVERSAL_DOORS,
    VALMART,
} from '../../constant/navigation/routeNames';

function BrowserNavbar({ theme }) {
    const [t] = useTranslation('navbar');

    const backgroundColor = useScrollStyle();

    const location = useLocation();

    const [language, changeLocale] = useLanguage();

    const onLanguageChange = async () => {
        await changeLocale();

        localStorage.setItem('indotechLocale', language);
    };

    const productsActive = [TECH_ROLL_ULTRA, TECH_ROLL_PRO, TECH_ROLL_MEGA, TECH_ROLL_LHR, TECH_ROLL_LITE].some((element) => element === location.pathname);
    const distributorsActive = [APEX, DEK, CFM_GROUP, LG_DOORS, ANDRE_INDUSTRIES, UNIVERSAL_DOORS, LONGPRE_DOORS, SIMS, VALMART, DRAKKAR].some(
        (element) => element === location.pathname,
    );

    return (
        <div
            className={`fixed z-20 flex w-full flex-row justify-between ${theme === LIGHT ? 'bg-white' : ''} pr-4`}
            style={{ backgroundColor: theme === DARK ? backgroundColor : '', transition: 'all ease-in-out 0.5s' }}
        >
            <div className={'flex flex-row items-start'}>
                <NavLogoLink
                    theme={theme}
                    linkTo={HOME}
                />

                <div className={'mt-4 flex flex-row'}>
                    <NavLink
                        title={t('about_us')}
                        theme={theme}
                        linkTo={ABOUT}
                        active={location.pathname === ABOUT}
                    />

                    <ProductsDropDownMenu
                        theme={theme}
                        active={productsActive}
                    />

                    <DistributorsDropDownMenu
                        theme={theme}
                        active={distributorsActive}
                    />

                    <NavLink
                        title={t('realisations')}
                        theme={theme}
                        linkTo={REALISATIONS}
                        active={location.pathname === REALISATIONS}
                    />

                    <NavLink
                        title={t('tech_info')}
                        theme={theme}
                        linkTo={TECH_INFO}
                        active={location.pathname === TECH_INFO}
                    />
                </div>
            </div>

            <div className={'mt-4 flex flex-row items-start'}>
                <NavLink
                    title={t('contact_us')}
                    theme={theme}
                    linkTo={CONTACT}
                    active={location.pathname === CONTACT}
                />

                <IndotechLinkButton
                    title={t('quote')}
                    bgColor={'bg-indotech-orange'}
                    linkTo={QUOTE}
                    containerStyle={'mt-3'}
                    textStyle={'line-clamp-1'}
                />

                <div className={`mt-2 h-10 border border-l ${theme === DARK ? 'border-white' : 'border-indotech-primary_blue'}`} />

                <NavLink
                    title={language}
                    theme={theme}
                    onClick={onLanguageChange}
                    fontStyle={'font-semibold'}
                />
            </div>
        </div>
    );
}

BrowserNavbar.propTypes = {
    theme: PropTypes.oneOf([LIGHT, DARK]),
};

BrowserNavbar.defaultProps = {
    theme: LIGHT,
};

export default BrowserNavbar;
