import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getModalBgColor } from '../../../utils/styles';

import { LIGHT } from '../../../constant/navbarTheme';
import useLanguage from '../../../hooks/useLanguage';
import NavLink from '../NavLink';

import {
    ABOUT,
    ANDRE_INDUSTRIES,
    APEX,
    CFM_GROUP,
    CONTACT,
    DEK,
    DRAKKAR,
    LG_DOORS,
    LONGPRE_DOORS,
    QUOTE,
    REALISATIONS,
    SIMS,
    TECH_INFO,
    TECH_ROLL_LHR,
    TECH_ROLL_LITE,
    TECH_ROLL_MEGA,
    TECH_ROLL_PRO,
    TECH_ROLL_ULTRA,
    UNIVERSAL_DOORS,
    VALMART,
} from '../../../constant/navigation/routeNames';

function MainDrawerContent({ theme, setProductsToggled, setDistributorsToggled, onLinkClick }) {
    const [t] = useTranslation('navbar');

    const location = useLocation();

    const [language, changeLocale] = useLanguage();

    const onLanguageChange = async () => {
        await changeLocale();

        localStorage.setItem('indotechLocale', language);
    };

    const productsActive = [TECH_ROLL_ULTRA, TECH_ROLL_PRO, TECH_ROLL_MEGA, TECH_ROLL_LHR, TECH_ROLL_LITE].some((element) => element === location.pathname);
    const distributorsActive = [APEX, DEK, CFM_GROUP, LG_DOORS, ANDRE_INDUSTRIES, UNIVERSAL_DOORS, LONGPRE_DOORS, SIMS, VALMART, DRAKKAR].some(
        (element) => element === location.pathname,
    );

    return (
        <div className={`flex h-full flex-col items-center justify-center ${getModalBgColor(theme)}`}>
            <NavLink
                title={t('about_us')}
                theme={theme}
                linkTo={ABOUT}
                active={location.pathname === ABOUT}
                onLinkClick={onLinkClick}
            />

            <NavLink
                title={t('products')}
                theme={theme}
                active={productsActive}
                onClick={() => setProductsToggled(true)}
            />

            <NavLink
                title={t('distributors')}
                theme={theme}
                active={distributorsActive}
                onClick={() => setDistributorsToggled(true)}
            />

            <NavLink
                title={t('realisations')}
                theme={theme}
                linkTo={REALISATIONS}
                active={location.pathname === REALISATIONS}
                onLinkClick={onLinkClick}
            />

            <NavLink
                title={t('tech_info')}
                theme={theme}
                linkTo={TECH_INFO}
                active={location.pathname === TECH_INFO}
                onLinkClick={onLinkClick}
            />

            <NavLink
                title={t('contact_us')}
                theme={theme}
                linkTo={CONTACT}
                active={location.pathname === CONTACT}
                onLinkClick={onLinkClick}
            />

            <NavLink
                title={t('quote')}
                theme={theme}
                linkTo={QUOTE}
                textColor={'text-indotech-orange'}
                active={location.pathname === QUOTE}
                onLinkClick={onLinkClick}
            />

            <NavLink
                title={language}
                theme={theme}
                onClick={onLanguageChange}
                fontStyle={'font-semibold'}
            />
        </div>
    );
}

MainDrawerContent.propTypes = {
    theme: PropTypes.string,
    setProductsToggled: PropTypes.func,
    setDistributorsToggled: PropTypes.func,
    onLinkClick: PropTypes.func,
};

MainDrawerContent.defaultProps = {
    theme: LIGHT,
    setProductsToggled: () => {},
    setDistributorsToggled: () => {},
    onLinkClick: () => {},
};

export default MainDrawerContent;
