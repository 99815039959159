import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getIndotechLogo } from '../../utils/styles';

import { DARK, LIGHT } from '../../constant/navbarTheme.js';

function NavLogoLink({ theme, linkTo }) {
    const location = useLocation();

    return (
        <Link
            to={linkTo}
            state={{ prevPath: location.pathname }}
            className={'sm:hover:opacity-50'}
        >
            <img
                src={getIndotechLogo(theme)}
                className={'mx-3 max-h-20 min-h-20 min-w-48 max-w-48 sm:mx-6'}
                alt={'Logo'}
            />
        </Link>
    );
}

NavLogoLink.propTypes = {
    theme: PropTypes.oneOf([LIGHT, DARK]),
    linkTo: PropTypes.string,
};

NavLogoLink.defaultProps = {
    theme: LIGHT,
    linkTo: '',
};

export default NavLogoLink;
