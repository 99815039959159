import serviceLogo from '../assets/logos/Handshake.svg';
import reliabilityLogo from '../assets/logos/Protect.svg';
import qualityLogo from '../assets/logos/Star.svg';

export const companyValues = [
    {
        title: 'our_values.reliability.title',
        description: 'our_values.reliability.description',
        logo: reliabilityLogo,
    },
    {
        title: 'our_values.quality.title',
        description: 'our_values.quality.description',
        logo: qualityLogo,
    },
    {
        title: 'our_values.service.title',
        description: 'our_values.service.description',
        logo: serviceLogo,
    },
];
