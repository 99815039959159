import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { navLinkTextColor } from '../../utils/styles.js';

import { DARK, LIGHT } from '../../constant/navbarTheme.js';

function NavLink({ title, secondTitle, theme, containerStyle, textColor, secondTextColor, fontStyle, active, linkTo, onClick, onLinkClick, onMouseEnter }) {
    const navigate = useNavigate();
    const location = useLocation();

    const activeStyle = (type = 'primary') => {
        if (type === 'secondary') {
            return 'underline font-semibold';
        }

        if (!secondTitle) {
            return 'underline font-semibold';
        }

        return 'font-semibold';
    };

    const linkPressed = (e) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        } else {
            navigate(linkTo, { state: { prevPath: location.pathname } });

            onLinkClick();
        }
    };

    return (
        <Link
            to={linkTo}
            state={{ prevPath: location.pathname }}
            className={`flex flex-row ${containerStyle} hover:opacity-50`}
            onClick={(e) => linkPressed(e)}
            onMouseEnter={onMouseEnter}
        >
            <div className={`${navLinkTextColor(theme, textColor)} my-4 line-clamp-1 text-center sm:mx-4 ${fontStyle} ${active ? activeStyle('primary') : ''}`}>
                {title}
            </div>

            {secondTitle && (
                <div
                    className={`${navLinkTextColor(theme, secondTextColor)} my-4 ml-1 text-center sm:-ml-2 ${fontStyle} ${active ? activeStyle('secondary') : ''}`}
                >
                    {secondTitle}
                </div>
            )}
        </Link>
    );
}

NavLink.propTypes = {
    title: PropTypes.string,
    secondTitle: PropTypes.string,
    theme: PropTypes.oneOf([DARK, LIGHT]),
    linkTo: PropTypes.string,
    textColor: PropTypes.string,
    secondTextColor: PropTypes.string,
    fontStyle: PropTypes.string,
    containerStyle: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    onLinkClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
};

NavLink.defaultProps = {
    title: '',
    secondTitle: '',
    theme: LIGHT,
    linkTo: null,
    textColor: '',
    secondTextColor: '',
    fontStyle: '',
    containerStyle: '',
    active: false,
    onClick: undefined,
    onLinkClick: () => {},
    onMouseEnter: () => {},
};

export default NavLink;
