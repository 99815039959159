import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import OperatorInstallation from './OperatorInstallation';
import OperatorTension from './OperatorTension';

function Operator({ form, setForm }) {
    const [t] = useTranslation('quote');

    return (
        <>
            <IndotechFormTitle
                title={t('sections.operator.sectionTitle')}
                classStyle={'pb-4'}
            />

            <OperatorTension
                form={form}
                setForm={setForm}
            />

            <OperatorInstallation
                form={form}
                setForm={setForm}
            />
        </>
    );
}

Operator.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

Operator.defaultProps = {
    form: {},
    setForm: () => {},
};

export default Operator;
