import React from 'react';
import PropTypes from 'prop-types';

import { AnimatePresence, motion } from 'framer-motion';

import CurtainColor from './CurtainColor';
import TechnicalDetail from './TechnicalDetail';

function DoorRadioMoreInfo({ showMoreInfo, curtainOptions, selectedCurtain, setSelectedCurtain, technicalDetails }) {
    return (
        <AnimatePresence mode={'wait'}>
            {showMoreInfo && (
                <motion.div
                    key={'doorInfo'}
                    className={' w-full'}
                    style={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <motion.div
                        className={'p-4'}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        <CurtainColor
                            curtainOptions={curtainOptions}
                            selectedCurtain={selectedCurtain}
                            setSelectedCurtain={setSelectedCurtain}
                        />

                        <TechnicalDetail technicalDetails={technicalDetails} />
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

DoorRadioMoreInfo.propTypes = {
    showMoreInfo: PropTypes.bool,
    curtainOptions: PropTypes.object,
    selectedCurtain: PropTypes.string,
    setSelectedCurtain: PropTypes.func,
    technicalDetails: PropTypes.object,
};

DoorRadioMoreInfo.defaultProps = {
    showMoreInfo: false,
    curtainOptions: [],
    selectedCurtain: '',
    setSelectedCurtain: () => {},
    technicalDetails: [],
};

export default DoorRadioMoreInfo;
