import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useWindowDimensions from '../../hooks/useWindowDimensions';

function Banner({ logo, title }) {
    const { isMobile } = useWindowDimensions();
    const [t] = useTranslation('ourDistributors');

    const mobileStyle = `flex w-full flex-col p-8`;
    const browserStyle = `flex w-full flex-row items-center justify-start pb-10 pl-20 pt-32 max-w-screen-xl`;

    return (
        <div className={`${isMobile ? mobileStyle : browserStyle}`}>
            {!isMobile && (
                <img
                    src={logo}
                    className={'mr-16'}
                />
            )}

            <p className={`mr-4 text-6xl font-bold text-indotech-primary_blue`}>{t(title.firstPart)}</p>
            <p className={'text-6xl font-bold text-indotech-orange'}>{t(title.highlightedPart)}</p>
        </div>
    );
}

Banner.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
};

Banner.defaultProps = {
    logo: '',
    title: '',
};

export default Banner;
