import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import L5ImagePreviewModal from './L5ImagePreviewModal';

function L5Gallery({ images, columns, columnGap }) {
    const { width } = useWindowDimensions();

    // Determine the number of columns based on viewport width
    const getColumnCount = () => {
        if (width >= 1024) return columns; // Large screens
        if (width >= 768) return columns - 1; // Medium screens
        return 1; // Small screens
    };

    const [selectedImage, setSelectedImage] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const onImageClick = (e) => {
        setSelectedImage(e);
        setShowModal(true);
    };

    return (
        <>
            <div
                style={{
                    columnCount: getColumnCount(),
                    columnGap: columnGap, // Adjust the gap between columns as needed
                }}
                className="relative"
            >
                {images.map((item, index) => (
                    <div
                        key={index}
                        className="mb-2 break-inside-avoid"
                    >
                        <img
                            src={item.src}
                            alt={item.title}
                            className="h-auto w-full cursor-pointer rounded object-cover transition-all duration-300 ease-in-out hover:opacity-70"
                            onClick={() => onImageClick(index)}
                        />
                    </div>
                ))}
            </div>

            <L5ImagePreviewModal
                isOpened={showModal}
                setOpened={(e) => setShowModal(e)}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                images={images}
            />
        </>
    );
}

L5Gallery.protoTypes = {
    images: PropTypes.object,
    columns: PropTypes.number,
    columnGap: PropTypes.string,
};

L5Gallery.defaultProps = {
    images: {},
    columns: 3,
    columnGap: '8px',
};

export default L5Gallery;
