import React from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function L5VideoGallery({ videos }) {
    const { width, isMobile } = useWindowDimensions();
    const [t] = useTranslation('realisations');

    const opts = {
        width: isMobile ? width : width / 1.3,
        height: isMobile ? width / 2 : width / 3,
    };

    return (
        <div className={'w-full flex-col'}>
            <div>{videos.label}</div>

            {videos.map((video) => (
                <div
                    className={'mb-10'}
                    key={video.label}
                >
                    <div className={'mb-4 pl-4 text-4xl font-bold text-indotech-primary_blue sm:pl-0'}>{t(video.label)}</div>

                    <YouTube
                        id={video.videoId}
                        title={video.label}
                        videoId={video.videoId}
                        opts={opts}
                        loading={'lazy'}
                    />
                </div>
            ))}
        </div>
    );
}
