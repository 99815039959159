import React from 'react';
import PropTypes from 'prop-types';

import IndotechSeparator from '../ui-elements/IndotechSeparator';
import ContactInformation from './contact-form/ContactInformation';
import Industry from './contact-form/Industry';
import Message from './contact-form/Message';
import PreferredCommunicationMethod from './contact-form/PreferredCommunicationMethod';

function ContactForm({ form, setForm }) {
    return (
        <div className={`w-full max-w-screen-xl border-t border-indotech-primary_blue px-4 py-8 sm:rounded-2xl sm:border sm:p-8`}>
            <ContactInformation
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Industry
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Message
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <PreferredCommunicationMethod
                form={form}
                setForm={setForm}
            />
        </div>
    );
}

ContactForm.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

ContactForm.defaultProps = {
    form: {},
    setForm: () => {},
};

export default ContactForm;
