import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isDev } from '../utils/utils';

import { sendMail } from '../api';

import Footer from '../components/footer/Footer';
import QuoteBanner from '../components/quote/QuoteBanner';
import QuoteForm from '../components/quote/QuoteForm';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import IndotechButton, { BUTTON_TYPE } from '../components/ui-elements/IndotechButton';
import Section from '../components/ui-elements/Section';

import { contact } from '../constant/contact';
import { quoteMail } from '../constant/messages';
import { useEmailValidation } from '../hooks/useEmailValidation';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const defaultQuoteForm = {
    firstName: '',
    lastName: '',
    businessName: '',
    jobTitle: '',
    email: '',
    phone: '',
    doorType: '',
    doorModel: '',
    curtain: '',
    width1: '',
    height1: '',
    qty1: '',
    width2: '',
    height2: '',
    qty2: '',
    width3: '',
    height3: '',
    qty3: '',
    doorInstallation: '',
    operatorTension: '',
    operatorInstallation: '',
    electrical: '',
    guides: '',
    expedition: '',
    message: '',
};

function Quote() {
    const [t] = useTranslation('quote');

    const [form, setForm] = useState(defaultQuoteForm);
    const [isEmailSending, setIsEmailSending] = useState(false);

    const { isMobile } = useWindowDimensions();

    const isValidEmail = useEmailValidation(form.email);
    const isFormValid = isValidEmail && [form.firstName, form.lastName, form.businessName, form.email, form.phone].every((field) => field !== '');

    async function sendQuoteForm() {
        if (!isFormValid) {
            return;
        }

        setIsEmailSending(true);

        const mailData = {
            from: 'no-reply@indotech.ca',
            to: isDev ? 'service@loopfive.ca' : contact.EMAIL.value,
            subject: 'New Quote - Indotech website',
            html: quoteMail(form),
        };

        try {
            await sendMail(mailData, t('quote_sent'), t('error'));
            setForm({ ...defaultQuoteForm });
        } catch (e) {
            /* empty */
        } finally {
            setIsEmailSending(false);
        }
    }

    return (
        <AnimatedPage>
            <Section classStyle={'p-4 sm:px-12 sm:pt-28'}>
                <QuoteBanner />
            </Section>

            <Section classStyle={'p-4 sm:px-12'}>
                <QuoteForm
                    form={form}
                    setForm={setForm}
                />
            </Section>

            <Section classStyle={'p-4 sm:px-12'}>
                <div className={`flex w-full max-w-screen-xl justify-end py-8 ${isMobile && 'px-4'}`}>
                    <IndotechButton
                        classStyle={`px-16 py-4 ${isMobile && 'w-full'}`}
                        isLoading={isEmailSending}
                        onClick={sendQuoteForm}
                        type={BUTTON_TYPE.BLUE}
                        isDisabled={!isFormValid}
                    >
                        <div className={'text-lg font-bold text-white'}>{t('send').toUpperCase()}</div>
                    </IndotechButton>
                </div>
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

export default Quote;
