import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import IndotechTextInput from '../../ui-elements/IndotechTextInput';

function Message({ form, setForm, subtitleLabel }) {
    const [t] = useTranslation('contactUs');

    return (
        <>
            <IndotechFormTitle
                title={t('message')}
                classStyle={'pb-4'}
            />

            {subtitleLabel && (
                <IndotechFormTitle
                    title={subtitleLabel}
                    isSubtitle={true}
                    classStyle={'pb-4'}
                />
            )}

            <IndotechTextInput
                label={t('write_message')}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
                multiline={true}
                value={form.message}
            />
        </>
    );
}

Message.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    subtitleLabel: PropTypes.string,
};

Message.defaultProps = {
    form: {},
    setForm: () => {},
    subtitleLabel: '',
};

export default Message;
