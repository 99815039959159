import React from 'react';
import { useTranslation } from 'react-i18next';

function Banner() {
    const [t] = useTranslation('contactUs');

    return (
        <div className={'w-full max-w-screen-xl'}>
            <div className={'flex w-full'}>
                <div className={'mr-4 text-4xl text-indotech-primary_blue sm:text-5xl'}>{t('contact')}</div>
                <div className={'text-4xl text-indotech-orange sm:text-5xl'}>{t('indotech').toUpperCase()}</div>
            </div>

            <div className={'mt-6 text-3xl text-indotech-primary_blue'}>{t('call_us')}</div>
        </div>
    );
}

export default Banner;
