import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import IllustrationMoreInfoBanner from './IllustrationMoreInfoBanner';

const HEIGHT_TRANSITION_DURATION = 0.3;
function IllustrationMoreInfo({ component, onClick }) {
    const [t] = useTranslation('ourProducts');

    const [animationDone, setAnimationDone] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    function onClose() {
        setIsClosing(true);

        onClick();
    }

    return (
        <motion.div
            className={'rounded-2xl bg-white'}
            style={{ width: 0, height: 0, opacity: 0 }}
            transition={{ duration: HEIGHT_TRANSITION_DURATION, delayChildren: 2000 }}
            animate={{ width: '100%', height: '80%', opacity: 1 }}
            exit={{ width: 0, height: 0, opacity: 0 }}
            onAnimationComplete={() => setAnimationDone(true)}
        >
            {!isClosing && (
                <motion.div
                    className={'flex h-full w-full flex-col rounded-2xl px-6 py-4'}
                    style={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: HEIGHT_TRANSITION_DURATION }}
                >
                    {animationDone && (
                        <>
                            <IllustrationMoreInfoBanner
                                number={component.number}
                                title={t(component.title)}
                                onClose={onClose}
                            />

                            <div className={'flex flex-1 flex-row items-start'}>
                                <div className={'flex basis-1/3'}>
                                    <img
                                        src={component.img}
                                        className={'w-full'}
                                        alt={'image'}
                                    />
                                </div>

                                <div className={'ml-4 flex basis-2/3 text-justify text-lg font-semibold text-indotech-primary_blue'}>
                                    {t(component.description)}
                                </div>
                            </div>
                        </>
                    )}
                </motion.div>
            )}
        </motion.div>
    );
}

IllustrationMoreInfo.propTypes = {
    component: PropTypes.object,
    onClick: PropTypes.func,
};

IllustrationMoreInfo.defaultProps = {
    component: {},
    onClick: () => {},
};

export default IllustrationMoreInfo;
