import React from 'react';
import PropTypes from 'prop-types';

import { createTheme, TextField, ThemeProvider } from '@mui/material';

import { Colors } from '../../utils/Colors';

function IndotechTextInput({
    label,
    required,
    disabled,
    isIncorrect,
    incorrectWarningText,
    multiline,
    onChange,
    withMarginRight,
    withMarginLeft,
    withMarginY,
    type,
    autocomplete,
    value,
}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: Colors.primary_blue,
            },
            secondary: {
                main: Colors.secondary_blue,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <TextField
                id={'outlined-controlled'}
                className={'flex flex-1'}
                size={'small'}
                color={'secondary'}
                sx={{
                    '& label.Mui-focused': {
                        color: Colors.secondary_blue,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1.5,
                        borderColor: Colors.primary_blue,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: Colors.secondary_blue,
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: Colors.secondary_blue,
                    },
                    marginRight: withMarginRight ? 3 : 0,
                    marginLeft: withMarginLeft ? 3 : 0,
                    marginY: withMarginY ? 1 : 0,
                }}
                label={label}
                required={required}
                disabled={disabled}
                error={isIncorrect}
                helperText={isIncorrect ? incorrectWarningText : ''}
                multiline={multiline}
                rows={multiline ? 8 : 1}
                fullWidth={true}
                onChange={onChange}
                type={type}
                autoComplete={autocomplete}
                value={value}
            />
        </ThemeProvider>
    );
}

IndotechTextInput.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isIncorrect: PropTypes.bool,
    incorrectText: PropTypes.string,
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    withMarginRight: PropTypes.bool,
    withMarginLeft: PropTypes.bool,
    withMarginY: PropTypes.bool,
    value: PropTypes.any,
};

IndotechTextInput.defaultProps = {
    label: '',
    require: false,
    disabled: false,
    isIncorrect: false,
    incorrectText: '',
    multiline: false,
    onChange: () => {},
    type: '',
    autoComplete: '',
    withMarginRight: false,
    withMarginLeft: false,
    withMarginY: true,
    value: '',
};

export default IndotechTextInput;
