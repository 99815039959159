export const contactUsMail = (form) => {
    return `<p>Hello Indotech!</p>
            <p>&nbsp;</p>
            <p>You got a new contact request from ${form.businessName || 'unknown business'}<br><br></p>
            <p><br><br>Here are the information about the request:<br><br>First Name: ${form.firstName}</p>
            <p>Last Name: ${form.lastName}</p>
            <p>Phone Number: ${form.phone}</p>
            <p>Email: ${form.email}</p>
            <p>Job title: ${form.jobTitle}</p>
            <p>Industry: ${form.industry}</p>
            <p>Product Range: ${form.productRange}</p>
            <p>Preferred Communication: ${form.communication}</p>
            <p> </p>
            <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${form.message}</p>
            <p>&nbsp;</p>
            <p>Best wishes, go get it!</p>
           `;
};

export const quoteMail = (form) => {
    return `<p>Hello Indotech!</p>
            <p>&nbsp;</p>
            <p>You got a new quote submission from ${form.businessName || 'unknown business'}<br><br></p>
            <p><br><br>Here are the information about the submission:<br><br>First Name: ${form.firstName}</p>
            <p>Last Name: ${form.lastName}</p>
            <p>Phone Number: ${form.phone}</p>
            <p>Email: ${form.email}</p>
            <p>Job title: ${form.jobTitle}</p>
            <p>Door Type: ${form.doorType}</p>
            <p>Door Model: ${form.doorModel}</p>
            <p>Curtain Color: ${form.curtain}</p>
            <p>Dimensions (width x height, quantity):</p>
            <ul>
                <li>Door 1: ${form.width1} x ${form.height1}, qty: ${form.qty1} </li>
                <li>Door 2: ${form.width2} x ${form.height2}, qty: ${form.qty2} </li>
                <li>Door 3: ${form.width3} x ${form.height3}, qty: ${form.qty3} </li>
            </ul>
            <p>Door Installation: ${form.doorInstallation}</p>
            <p>Operator Voltage: ${form.operatorTension}</p>
            <p>Operator Installation: ${form.operatorInstallation}</p>
            <p>Electrical: ${form.electrical}</p>
            <p>Guide Rails: ${form.guides}</p>
            <p>Expedition : ${form.expedition}</p>
            <p> </p>
            <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${form.message}</p>
            <p>&nbsp;</p>
            <p>Best wishes, go get it!</p>
           `;
};
