import React from 'react';
import PropTypes from 'prop-types';

function Card({ index, header, content, footer }) {
    return (
        <div className={`my-4 w-full ${index % 2 === 1 ? 'sm:pl-4' : 'sm:pr-4'} sm:w-1/2`}>
            <div className={'flex h-full w-full flex-col rounded border border-indotech-primary_blue p-6'}>
                {header}
                {content}
                {footer}
            </div>
        </div>
    );
}

Card.propTypes = {
    index: PropTypes.number,
    header: PropTypes.element,
    content: PropTypes.element,
    footer: PropTypes.element,
};

Card.defaultProps = {
    index: 0,
    header: null,
    content: null,
    footer: null,
};

export default Card;
