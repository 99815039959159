import { IndotechButtonSizes } from '../enums/IndotechButtonSizes.js';
import { L5RadioButtonSize } from '../enums/L5RadioButtonSize';

import darkLogo_v2 from '../assets/logos/indotechLogoDark_v2.png';
import lightLogo_v2 from '../assets/logos/indotechLogoLight_v2.png';
import { DARK } from '../constant/navbarTheme.js';
import { Colors } from './Colors';

export function indotechButtonStyle(size) {
    switch (size) {
        case IndotechButtonSizes.SM:
            return 'mx-2 py-2 px-4 rounded-3xl text-xs sm:text-base';
        case IndotechButtonSizes.MD:
            return 'mx-3.5 py-1 px-5 rounded-3xl text-xs sm:text-base';
        case IndotechButtonSizes.LG:
            return 'm-4 py-5 rounded-3xl text-sm sm:text-lg';
        case IndotechButtonSizes.XL:
            return 'm-4 py-5 rounded-full text-sm sm:text-lg';
        default:
            return 'mx-3.5 py-2 px-5 rounded-3xl text-xs sm:text-base';
    }
}

export function l5RadioButtonStyle(size) {
    switch (size) {
        case L5RadioButtonSize.SM:
            return { outer: 'h-3 w-3', inner: 'h-1.5 w-1.5', text: 'text-sm', subText: 'text-2xs' };
        case L5RadioButtonSize.MD:
            return { outer: 'h-5 w-5', inner: 'h-2.5 w-2.5', text: 'text-base', subText: 'text-xs' };
        case L5RadioButtonSize.LG:
            return { outer: 'h-6 w-6', inner: 'h-3 w-3', text: 'text-lg', subText: 'text-sm' };
        default:
            return { outer: 'h-5 w-5', inner: 'h-2.5 w-2.5', text: 'text-base', subText: 'text-xs' };
    }
}

export const pdfItemStyle = (size, isMobile) => {
    if (isMobile) {
        if (size === 'sm') {
            return { dotSize: 8, textSize: 'text-sm', marginY: 'my-0.5' };
        }

        return { dotSize: 10, textSize: 'text-base', marginY: 'my-1' };
    }

    if (size === 'sm') {
        return { dotSize: 10, textSize: 'text-base', marginY: 'my-0.5' };
    }

    return { dotSize: 12, textSize: 'text-2xl', marginY: 'my-1' };
};

export function navLinkTextColor(theme, textColor) {
    if (textColor) {
        return textColor;
    }

    if (theme === DARK) {
        return 'text-white';
    }

    return 'text-indotech-primary_blue';
}

export function getIndotechLogo(theme) {
    if (theme === DARK) {
        return darkLogo_v2;
    }

    return lightLogo_v2;
}

export function getHambugerColor(theme) {
    if (theme === DARK) {
        return 'white';
    }

    return Colors.primary_blue;
}

export function getModalBgColor(theme, inHexcode = false) {
    if (theme === DARK) {
        if (inHexcode) {
            return Colors.darkoverlay;
        }

        return 'bg-indotech-darkoverlay';
    }

    if (inHexcode) {
        return 'white';
    }

    return 'bg-white';
}
