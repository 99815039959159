import React from 'react';
import PropTypes from 'prop-types';

import TechnicalDetailItem from './TechnicalDetailItem';

function TechnicalDetailList({ technicalDetails }) {
    return technicalDetails.map((techDetail) => (
        <TechnicalDetailItem
            key={techDetail}
            techDetail={techDetail}
        />
    ));
}

TechnicalDetailList.propTypes = {
    technicalDetails: PropTypes.object,
};

TechnicalDetailList.defaultProps = {
    technicalDetails: [],
};

export default TechnicalDetailList;
