import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ContactInformation from '../contact-us/contact-form/ContactInformation';
import Message from '../contact-us/contact-form/Message';
import IndotechSeparator from '../ui-elements/IndotechSeparator';
import Dimensions from './quote-form/Dimensions';
import DoorInstallation from './quote-form/DoorInstallation';
import DoorModel from './quote-form/DoorModel';
import DoorType from './quote-form/DoorType';
import Electrical from './quote-form/Electrical';
import Expedition from './quote-form/Expedition';
import GuideRails from './quote-form/GuideRails';
import Operator from './quote-form/Operator';

function QuoteForm({ form, setForm }) {
    const [t] = useTranslation('quote');

    return (
        <div className={`w-full max-w-screen-xl border-t border-indotech-primary_blue px-4 py-8 sm:rounded-2xl sm:border sm:p-8`}>
            <ContactInformation
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <DoorType
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <DoorModel
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Dimensions
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <DoorInstallation
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Operator
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Electrical
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <GuideRails
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Expedition
                form={form}
                setForm={setForm}
            />

            <IndotechSeparator />

            <Message
                form={form}
                setForm={setForm}
                subtitleLabel={t('sections.message.placeholder')}
            />
        </div>
    );
}

QuoteForm.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

QuoteForm.defaultProps = {
    form: {},
    setForm: () => {},
};

export default QuoteForm;
