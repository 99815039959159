import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getModalBgColor } from '../../../utils/styles';

import { distributors } from '../../../constant/distributors';
import { LIGHT } from '../../../constant/navbarTheme';
import NavLink from '../NavLink';
import DrawerHeader from './DrawerHeader';

function DistributorsDrawerContent({ theme, setDistributorsToggled, onLinkClick }) {
    const [t] = useTranslation(['navbar', 'ourDistributors']);

    const location = useLocation();

    return (
        <>
            <DrawerHeader
                theme={theme}
                setToggled={setDistributorsToggled}
                title={t('distributors')}
            />

            <div className={`flex flex-col items-center justify-center ${getModalBgColor(theme)} `}>
                {distributors.map((distributor) => (
                    <NavLink
                        key={distributor.key}
                        theme={theme}
                        title={t(distributor.title.firstPart, { ns: 'ourDistributors' })}
                        secondTitle={t(distributor.title.highlightedPart, { ns: 'ourDistributors' })}
                        secondTextColor={'text-indotech-orange'}
                        linkTo={distributor.linkTo}
                        active={location.pathname === distributor.linkTo}
                        onLinkClick={onLinkClick}
                    />
                ))}
            </div>
        </>
    );
}

DistributorsDrawerContent.propTypes = {
    theme: PropTypes.string,
    setDistributorsToggled: PropTypes.func,
    onLinkClick: PropTypes.func,
};

DistributorsDrawerContent.defaultProps = {
    theme: LIGHT,
    setDistributorsToggled: () => {},
    onLinkClick: () => {},
};

export default DistributorsDrawerContent;
