import React from 'react';
import { useTranslation } from 'react-i18next';

import aboutUsBg from '../../assets/pictures/door_STMStinson.JPG';
import SectionTitle from '../ui-elements/SectionTitle';

export default function Banner() {
    const [t] = useTranslation('realisations');

    return (
        <div
            className="relative flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${aboutUsBg})`, maxHeight: '500px' }}
        >
            <div className="absolute inset-0 bg-black opacity-65"></div>
            <div className="relative z-10 p-4">
                <SectionTitle
                    title={t('realisations')}
                    classStyle={'text-indotech-orange brightness-[1]'}
                />
            </div>
        </div>
    );
}
