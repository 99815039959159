import React from 'react';
import PropTypes from 'prop-types';

import { L5RadioButtonSize } from '../../../enums/L5RadioButtonSize';

import L5RadioButton from './L5RadioButton';

function L5RadioButtonList({ selected, setSelected, options, color, itemContainerStyle, size, textStyle }) {
    function handleOptionChange(changeEvent) {
        setSelected(changeEvent.target.value);
    }

    return options.map((option) => (
        <L5RadioButton
            key={option.value}
            value={option.value}
            label={option.label}
            selectedOption={selected}
            handleOptionChange={handleOptionChange}
            color={color}
            size={size}
            itemContainerStyle={itemContainerStyle}
            textStyle={textStyle}
        />
    ));
}

L5RadioButtonList.propTypes = {
    options: PropTypes.object,
    color: PropTypes.string,
    itemContainerStyle: PropTypes.string,
    size: PropTypes.oneOf([L5RadioButtonSize.LG, L5RadioButtonSize.MD, L5RadioButtonSize.SM]),
    textStyle: PropTypes.string,
};

L5RadioButtonList.defaultProps = {
    options: [],
    color: '#000000',
    itemContainerStyle: '',
    size: L5RadioButtonSize.MD,
    textStyle: '',
};

export default L5RadioButtonList;
