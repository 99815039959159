import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CONTRACT_ABI from '../../assets/logos/contracts/logo_abi_v2.jpg';
import CONTRACT_ARCELOR from '../../assets/logos/contracts/logo_arcelor_v2.jpg';
import CONTRACT_CONCORDIA from '../../assets/logos/contracts/logo_concordia.png';
import CONTRACT_CONGRESS from '../../assets/logos/contracts/logo_congress_v2.jpg';
import CONTRACT_CP from '../../assets/logos/contracts/logo_CP.jpg';
import CONTRACT_HMR from '../../assets/logos/contracts/logo_hmr.jpg';
import CONTRACT_HYDRO from '../../assets/logos/contracts/logo_hydro.png';
import CONTRACT_JAZZ from '../../assets/logos/contracts/logo_jazz_v2.jpg';
import CONTRACT_LONGEUIL from '../../assets/logos/contracts/logo_longeuil.png';
import CONTRACT_MONTREAL from '../../assets/logos/contracts/logo_montreal.png';
import CONTRACT_RESOLU from '../../assets/logos/contracts/logo_resolu_v2.jpg';
import CONTRACT_ROU6E from '../../assets/logos/contracts/logo_rou6e.png';
import CONTRACT_SEPTILES from '../../assets/logos/contracts/logo_septiles_v2.jpg';
import CONTRACT_STADIUM from '../../assets/logos/contracts/logo_stadium_v2.jpg';
import CONTRACT_TRANSPORT_METROPOLITAIN from '../../assets/logos/contracts/logo_transport_metropolitain.jpg';
import CONTRACT_TRANSPORT_RTL from '../../assets/logos/contracts/logo_transport_rtl.jpg';
import CONTRACT_TRANSPORT_STL from '../../assets/logos/contracts/logo_transport_stl.jpg';
import CONTRACT_TRANSPORT_STM from '../../assets/logos/contracts/logo_transport_stm.jpg';
import CONTRACT_UOTTAWA from '../../assets/logos/contracts/logo_u_ottawa_v2.jpg';
import CONTRACT_VIARAIL from '../../assets/logos/contracts/logo_viarail.png';
import LHR1 from '../../assets/pictures/LHR/door_LHR_6437.jpg';
import LHR2 from '../../assets/pictures/LHR/door_LHR_devant.png';
import LHR3 from '../../assets/pictures/LHR/door_LHR_jonction3-2.JPG';
import LHR4 from '../../assets/pictures/LHR/door_LHR_jonction3-3.JPG';
import LHR6 from '../../assets/pictures/LHR/door_LHR_mul_logo.jpg';
import LHR5 from '../../assets/pictures/LHR/door_LHR_universelles.jpg';
import LITE2 from '../../assets/pictures/LITE/door_LITE_green.bmp';
import MEGA1 from '../../assets/pictures/MEGA/door_MEGA_megadome.jpg';
import MEGA2 from '../../assets/pictures/MEGA/door_MEGA_photo-15.jpg';
import PRO1 from '../../assets/pictures/PRO/door_PRO_cp.jpg';
import PRO2 from '../../assets/pictures/PRO/door_PRO_viarail.jpg';
import ULTRA2 from '../../assets/pictures/ULTRA/door_ULTRA_amt.jpg';
import ULTRA5 from '../../assets/pictures/ULTRA/door_ULTRA_AMT-Trans-de-lest.JPG';
import ULTRA3 from '../../assets/pictures/ULTRA/door_ULTRA_cit-1.JPG';
import ULTRA4 from '../../assets/pictures/ULTRA/door_ULTRA_dsc00002.JPG';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import L5Gallery from '../ui-elements/L5Gallery';
import L5VideoGallery from '../ui-elements/L5VideoGallery';

export const LHR_PICTURES = [
    {
        src: LHR1,
    },
    {
        src: LHR2,
    },
    {
        src: LHR3,
    },
    {
        src: LHR4,
    },
    {
        src: LHR5,
    },
    {
        src: LHR6,
    },
];
export const MEGA_PICTURES = [
    {
        src: MEGA1,
    },
    {
        src: MEGA2,
    },
];
export const PRO_PICTURES = [
    {
        src: PRO1,
    },
    {
        src: PRO2,
    },
];
export const ULTRA_PICTURES = [
    {
        src: ULTRA2,
    },
    {
        src: ULTRA3,
    },
    {
        src: ULTRA4,
    },
    {
        src: ULTRA5,
    },
];
export const LITE_PICTURES = [
    {
        src: LITE2,
    },
];

export const CONTRACT_LOGOS = [
    {
        src: CONTRACT_ABI,
    },
    {
        src: CONTRACT_ARCELOR,
    },
    {
        src: CONTRACT_CONCORDIA,
    },
    {
        src: CONTRACT_CONGRESS,
    },
    {
        src: CONTRACT_CP,
    },
    {
        src: CONTRACT_HMR,
    },
    {
        src: CONTRACT_HYDRO,
    },
    {
        src: CONTRACT_JAZZ,
    },
    {
        src: CONTRACT_LONGEUIL,
    },
    {
        src: CONTRACT_MONTREAL,
    },
    {
        src: CONTRACT_RESOLU,
    },
    {
        src: CONTRACT_ROU6E,
    },

    {
        src: CONTRACT_SEPTILES,
    },
    {
        src: CONTRACT_STADIUM,
    },
    {
        src: CONTRACT_TRANSPORT_METROPOLITAIN,
    },
    {
        src: CONTRACT_TRANSPORT_RTL,
    },
    {
        src: CONTRACT_TRANSPORT_STL,
    },
    {
        src: CONTRACT_TRANSPORT_STM,
    },
    {
        src: CONTRACT_UOTTAWA,
    },
    {
        src: CONTRACT_VIARAIL,
    },
];

const Videos = [
    {
        videoId: 'X6v-gPT5AZA',
        label: 'installation',
    },
    {
        videoId: '4FoblkxbFxY',
        label: 'brakes',
    },
    {
        videoId: '63lYKURQcgA',
        label: 'maintenance',
    },
    {
        videoId: '3gvxIra9lsQ',
        label: 'low_profile',
    },
];

const assets = [
    {
        label: 'ultra',
        images: ULTRA_PICTURES,
    },
    {
        label: 'pro',
        images: PRO_PICTURES,
    },
    {
        label: 'mega',
        images: MEGA_PICTURES,
    },
    {
        label: 'lhr',
        images: LHR_PICTURES,
    },
    {
        label: 'lite',
        images: LITE_PICTURES,
    },
    {
        label: 'contracts',
        images: CONTRACT_LOGOS,
    },
    {
        label: 'videos',
        images: Videos,
    },
];

export default function IndoTechGallery() {
    const [t] = useTranslation('realisations');
    const { isMobile } = useWindowDimensions();

    const [selectedAsset, setSelectedAsset] = useState(0);
    const isVideo = selectedAsset === assets.length - 1;

    return (
        <div className={`flex flex-${isMobile ? 'col' : 'row'} max-w-screen-2xl py-4`}>
            <div className={`justify-start ${!isMobile && 'border-r-2'} border-indotech-primary_blue px-8 ${isMobile && 'mb-4 w-full border-b-2 pb-2'}`}>
                <div className={'mb-4 text-2xl font-bold text-indotech-primary_blue'}>{t('repertoire')}:</div>

                <div className={`flex flex-${isMobile ? 'row' : 'col'} flex-wrap`}>
                    {assets.map((asset, index) => (
                        <div
                            key={asset.label}
                            className={`${isMobile ? 'mr-2' : ''} mb-2 ${selectedAsset === index && 'font-bold'} text-indotech-primary_blue underline transition-all ease-in-out hover:cursor-pointer sm:hover:opacity-50`}
                            onClick={() => setSelectedAsset(index)}
                        >
                            {t(asset.label)}
                        </div>
                    ))}
                </div>
            </div>

            <div className={`${isVideo ? 'px-0' : 'px-4'} w-full sm:px-8`}>
                {isVideo ? (
                    <L5VideoGallery videos={assets[selectedAsset].images} />
                ) : (
                    <L5Gallery
                        images={assets[selectedAsset].images}
                        columns={3}
                        columnGap={'8px'}
                    />
                )}
            </div>
        </div>
    );
}
