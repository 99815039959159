import React from 'react';
import { GoDotFill } from 'react-icons/go';
import PropTypes from 'prop-types';

import { Colors } from '../../../../utils/Colors';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';

function TechnicalDetailItem({ techDetail }) {
    const { isMobile } = useWindowDimensions();

    return (
        <div className={'my-0.5 ml-1 flex flex-row items-start sm:ml-2'}>
            <GoDotFill
                size={isMobile ? 14 : 18}
                color={Colors.primary_blue}
                className={'mr-1 mt-1.5 flex flex-shrink-0 sm:mr-2 sm:mt-1'}
            />

            <div className={'text-base text-indotech-primary_blue sm:text-base'}>{techDetail}</div>
        </div>
    );
}

TechnicalDetailItem.propTypes = {
    techDetail: PropTypes.string,
};

TechnicalDetailItem.defaultProps = {
    techDetail: '',
};

export default TechnicalDetailItem;
