import React from 'react';
import PropTypes from 'prop-types';

import L5LinkText from '../ui-elements/L5LinkText';

function ContactInformationItem({ icon, title, value, secondValueTitle, secondValue, href, secondHref }) {
    return (
        <div className={'mb-8 w-full pr-12'}>
            <div className={'mb-2 flex items-center'}>
                {icon}
                <div className={'ml-2 font-bold text-indotech-primary_blue sm:text-lg'}>{title}</div>
            </div>

            <L5LinkText
                classStyle={`text-indotech-primary_blue ${href && 'hover:cursor-pointer hover:opacity-50 sm:text-lg'}`}
                href={href}
                value={value}
            />

            {secondValue && (
                <div className={'flex'}>
                    <div className={'mr-2 text-indotech-orange sm:text-lg'}>{secondValueTitle}</div>
                    <L5LinkText
                        classStyle={`text-indotech-primary_blue ${href && 'hover:cursor-pointer hover:opacity-50 sm:text-lg'}`}
                        href={secondHref}
                        value={secondValue}
                    />
                </div>
            )}
        </div>
    );
}

ContactInformationItem.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    value: PropTypes.string,
    secondValueTitle: PropTypes.string,
    secondValue: PropTypes.string,
};

ContactInformationItem.defaultProps = {
    icon: null,
    title: '',
    value: '',
    secondValueTitle: '',
    secondValue: '',
};

export default ContactInformationItem;
