export const contact = {
    TECHNICAL_SERVICE: {
        value: '+1 (450) 641-8222',
    },
    PHONE: {
        value: '+1 (450) 641-8222',
        secondValue: '+1 (866) 835 8324',
    },
    ADDRESS: {
        value: '259 Chemin d’Alençon, Boucherville, Québec, J4B 0G9, Canada',
    },
    EMAIL: {
        value: 'info@indotech.ca',
    },
};
