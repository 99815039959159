import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import IndotechTextInput from '../../ui-elements/IndotechTextInput';

function DimensionsInput({ form, setForm, doorNumber }) {
    const [t] = useTranslation('quote');

    return (
        <div className={'mt-4'}>
            <IndotechFormTitle
                title={t(`sections.dimensions.subSections.door${doorNumber}`)}
                isSubtitle={true}
            />

            <div className={`mt-4 sm:flex`}>
                <IndotechTextInput
                    label={t('sections.dimensions.width')}
                    type={'text'}
                    autocomplete={'new-password'} // useful to remove random autocomplete that happens otherwise
                    onChange={(e) => setForm({ ...form, [`width${doorNumber}`]: e.target.value })}
                    withMarginRight={true}
                    value={form[`width${doorNumber}`]}
                />

                <IndotechTextInput
                    label={t('sections.dimensions.height')}
                    type={'text'}
                    autocomplete={'new-password'} // useful to remove random autocomplete that happens otherwise
                    onChange={(e) => setForm({ ...form, [`height${doorNumber}`]: e.target.value })}
                    withMarginRight={true}
                    value={form[`height${doorNumber}`]}
                />

                <IndotechTextInput
                    label={t('sections.dimensions.quantity')}
                    type={'text'}
                    autocomplete={'new-password'} // useful to remove random autocomplete that happens otherwise
                    onChange={(e) => setForm({ ...form, [`qty${doorNumber}`]: e.target.value })}
                    value={form[`qty${doorNumber}`]}
                />
            </div>
        </div>
    );
}

DimensionsInput.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    doorNumber: PropTypes.number,
};

DimensionsInput.defaultProps = {
    form: {},
    setForm: () => {},
    doorNumber: undefined,
};

export default DimensionsInput;
