import React from 'react';
import PropTypes from 'prop-types';

import SpecificationItem from './SpecificationItem';

function SpecificationsList({ specificationKeys }) {
    return specificationKeys.map((specKey, index) => (
        <SpecificationItem
            key={specKey.label}
            specificationKey={specKey}
            index={index}
        />
    ));
}

SpecificationsList.propTypes = {
    specificationKeys: PropTypes.object,
};

SpecificationsList.defaultProps = {
    specificationKeys: [],
};

export default SpecificationsList;
