import { Bounce, toast } from 'react-toastify';

import loopfiveServices from './loopfiveServices';

export const sendMail = async (mailData, successMessage, errorMessage) => {
    try {
        const response = await loopfiveServices.sendMail(mailData);

        toast.success(successMessage, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
        });

        return response;
    } catch (e) {
        toast.error(errorMessage, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
        });
    }
};
