import React from 'react';
import PropTypes from 'prop-types';

function IllustrationNumber({ number }) {
    return (
        <div className={'my-2 flex h-10 w-10 items-center justify-center rounded-full bg-indotech-secondary_orange text-xl font-semibold text-white'}>
            {number}
        </div>
    );
}

IllustrationNumber.propTypes = {
    number: PropTypes.number,
};

IllustrationNumber.defaultProps = {
    number: 0,
};

export default IllustrationNumber;
