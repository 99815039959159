import { Distributors } from '../enums/Distributors';

import apexLogo from '../assets/logos/distributors/apex_industries_logo.svg';
import dekLogo from '../assets/logos/distributors/dek_canada_logo.svg';
import drakkarLogo from '../assets/logos/distributors/drakkar_logo.jpeg';
import cfmLogo from '../assets/logos/distributors/groupe_cfm_logo.svg';
import andreLogo from '../assets/logos/distributors/industries_andre_logo.svg';
import lgLogo from '../assets/logos/distributors/portes_lg_logo.svg';
import longpreLogo from '../assets/logos/distributors/portes_longpres_logo.svg';
import universellesLogo from '../assets/logos/distributors/portes_universelles_logo.svg';
import simsLogo from '../assets/logos/distributors/sims_logo.svg';
import valmartLogo from '../assets/logos/distributors/valmart_logo.svg';

import { ANDRE_INDUSTRIES, APEX, CFM_GROUP, DEK, DRAKKAR, LG_DOORS, LONGPRE_DOORS, SIMS, UNIVERSAL_DOORS, VALMART } from './navigation/routeNames';

export const distributors = [
    {
        key: Distributors.APEX,
        logo: apexLogo,
        title: {
            fullName: 'apex_industries.full_name',
            firstPart: 'apex_industries.first_part',
            highlightedPart: 'apex_industries.highlighted_part',
        },
        linkTo: APEX,
        description: 'apex_industries.description',
        address: '100 Millennium Blvd, Moncton, NB E1E 2G8, Canada',
        contact: '1-800-268-3331',
        webPage: 'apexindustries.com',
        coordinates: {
            lat: 46.08998235588561,
            lng: -64.80751178650614,
        },
    },
    {
        key: Distributors.DEK,
        logo: dekLogo,
        title: {
            fullName: 'dek_canada.full_name',
            firstPart: 'dek_canada.first_part',
            highlightedPart: 'dek_canada.highlighted_part',
        },
        linkTo: DEK,
        description: 'dek_canada.description',
        address: '154 Bd Brunswick, Pointe-Claire, QC',
        contact: '(514) 685-5800',
        webPage: 'dekcanada.com',
        coordinates: {
            lat: 45.47007378233117,
            lng: -73.82440746013738,
        },
    },
    {
        key: Distributors.CFM_GROUP,
        logo: cfmLogo,
        title: {
            fullName: 'cfm_group.full_name',
            firstPart: 'cfm_group.first_part',
            highlightedPart: 'cfm_group.highlighted_part',
        },
        linkTo: CFM_GROUP,
        description: 'cfm_group.description',
        address: '150 rue Maltais, Sept-Îles, QC, Canada, Quebec',
        contact: '418-962-0661',
        webPage: 'www.facebook.com/p/Groupe-CFM-100069907086089',
        coordinates: {
            lat: 50.2101989641263,
            lng: -66.36683138690374,
        },
    },
    {
        key: Distributors.LG_DOORS,
        logo: lgLogo,
        title: {
            fullName: 'lg_doors.full_name',
            firstPart: 'lg_doors.first_part',
            highlightedPart: 'lg_doors.highlighted_part',
        },
        linkTo: LG_DOORS,
        description: 'lg_doors.description',
        address: '1621 rue Comtois, Trois Rivières (Québec)',
        contact: '819-376-7527',
        webPage: 'porteslgrenovation.com',
        coordinates: {
            lat: 46.34055654675074,
            lng: -72.56025798893485,
        },
    },
    {
        key: Distributors.ANDRE_INDUSTRIES,
        logo: andreLogo,
        title: {
            fullName: 'andre_industries.full_name',
            firstPart: 'andre_industries.first_part',
            highlightedPart: 'andre_industries.highlighted_part',
        },
        linkTo: ANDRE_INDUSTRIES,
        description: 'andre_industries.description',
        address: '290, rue Rodolphe-Besner, Vaudreuil-Dorion',
        contact: '(450) 455-3585',
        webPage: 'portedegarageandre.com',
        coordinates: {
            lat: 45.37439695836487,
            lng: -74.04702611596102,
        },
    },
    {
        key: Distributors.UNIVERSAL_DOORS,
        logo: universellesLogo,
        title: {
            fullName: 'universal_doors.full_name',
            firstPart: 'universal_doors.first_part',
            highlightedPart: 'universal_doors.highlighted_part',
        },
        linkTo: UNIVERSAL_DOORS,
        description: 'universal_doors.description',
        address: '2628 Bernard‑Pilon, St‑Mathieu‑de‑Beloeil, QC J3G 4S5',
        contact: '450-600-2296',
        webPage: 'portesuniverselles.com',
        coordinates: {
            lat: 45.57536288842769,
            lng: -73.2567613447883,
        },
    },
    {
        key: Distributors.LONGPRE_DOORS,
        logo: longpreLogo,
        title: {
            fullName: 'longpre_doors.full_name',
            firstPart: 'longpre_doors.first_part',
            highlightedPart: 'longpre_doors.highlighted_part',
        },
        linkTo: LONGPRE_DOORS,
        description: 'longpre_doors.description',
        address: '400, rue des Adirondacks, Québec (Québec)',
        contact: '418-661-8801',
        webPage: 'longpreinc.ca',
        coordinates: {
            lat: 46.87980294013478,
            lng: -71.18957543123844,
        },
    },
    {
        key: Distributors.SIMS,
        logo: simsLogo,
        title: {
            fullName: 'sims.full_name',
            firstPart: 'sims.first_part',
            highlightedPart: 'sims.highlighted_part',
        },
        linkTo: SIMS,
        description: 'sims.description',
        address: '5915 40 St SE #16, Calgary, AB T2C 2H6, Canada',
        contact: '403-279-7455',
        webPage: 'simsoverheaddoors.com',
        coordinates: {
            lat: 51.00042067001297,
            lng: -113.97743069815232,
        },
    },
    {
        key: Distributors.VALMART,
        logo: valmartLogo,
        title: {
            fullName: 'valmart.full_name',
            firstPart: 'valmart.first_part',
            highlightedPart: 'valmart.highlighted_part',
        },
        linkTo: VALMART,
        description: 'valmart.description',
        address: '9644 192nd St, Surrey, BC',
        contact: '(604) 888-6116',
        webPage: 'valmartdoors.com',
        coordinates: {
            lat: 49.177960120663805,
            lng: -122.68996945811624,
        },
    },
    {
        key: Distributors.DRAKKAR,
        logo: drakkarLogo,
        title: {
            fullName: 'drakkar.full_name',
            firstPart: 'drakkar.first_part',
            highlightedPart: 'drakkar.highlighted_part',
        },
        linkTo: DRAKKAR,
        description: 'drakkar.description',
        address: '1520, Boul. Saint-Charles Drummonville (Québec) J2C 4Z5',
        contact: '(819) 478-2385',
        webPage: 'portesdrakkar.com',
        coordinates: {
            lat: 45.90287178722624,
            lng: -72.46015229815232,
        },
    },
].sort((a, b) => {
    var textA = a.title.fullName.toUpperCase();
    var textB = b.title.fullName.toUpperCase();

    return textA < textB ? -1 : textA > textB ? 1 : 0;
});
