import React from 'react';
import { Oval } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

export const BUTTON_TYPE = {
    BLUE: 'BLUE',
    GOLD: 'GOLD',
    LIGHT_BLUE: 'LIGHT_BLUE',
};

function IndotechButton({ classStyle, children, isLoading, onClick, type, isDisabled }) {
    const bgColor = () => {
        switch (type) {
            case BUTTON_TYPE.BLUE:
                return 'bg-indotech-primary_blue';
            case BUTTON_TYPE.GOLD:
                return 'bg-indotech-orange';
            case BUTTON_TYPE:
                return 'bg-indotech-secondary_blue';
        }
    };

    const loaderColor = () => {
        switch (type) {
            case BUTTON_TYPE.BLUE:
                return 'white';
            case BUTTON_TYPE.GOLD:
                return 'white';
            case BUTTON_TYPE:
                return Colors.primary_blue;
        }
    };

    return (
        <div
            className={`flex items-center justify-center rounded-full transition-all duration-200 ease-in-out ${classStyle} ${bgColor()}  ${isDisabled ? 'opacity-80 hover:cursor-not-allowed' : 'hover:cursor-pointer hover:opacity-80'}`}
            onClick={onClick}
        >
            {isLoading ? (
                <Oval
                    visible={true}
                    height="30"
                    width="30"
                    color={loaderColor()}
                    ariaLabel="oval-loading"
                />
            ) : (
                <>{children}</>
            )}
        </div>
    );
}

IndotechButton.propTypes = {
    classStyle: PropTypes.string,
    children: PropTypes.element,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    isDisabled: PropTypes.bool,
};

IndotechButton.defaultProps = {
    classStyle: '',
    children: <></>,
    isLoading: false,
    onClick: () => {},
    type: BUTTON_TYPE.BLUE,
    isDisabled: false,
};

export default IndotechButton;
