import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AnimatePresence, motion } from 'framer-motion';

import IllustrationMoreInfo from './IllustrationMoreInfo';
import IllustrationsMappingBrowser from './IllustrationsMappingBrowser';

function IllustrationsListBrowser({ components }) {
    const [itemsHidden, setItemsHidden] = useState(false);
    const [moreInfoItem, setMoreInfoItem] = useState(null);

    function closeMoreInfo() {
        setItemsHidden(false);

        setMoreInfoItem(null);
    }
    function onItemClick(component) {
        setItemsHidden(true);

        setMoreInfoItem(component);
    }

    return (
        <AnimatePresence mode={'wait'}>
            {!itemsHidden ? (
                <motion.div
                    key={'item'}
                    className={'flex flex-1 flex-wrap sm:ml-4'}
                >
                    <IllustrationsMappingBrowser
                        components={components}
                        onItemClick={onItemClick}
                    />
                </motion.div>
            ) : (
                <motion.div
                    key={'moreInfo'}
                    className={'flex flex-1 items-center justify-center p-3 sm:ml-4'}
                >
                    <IllustrationMoreInfo
                        component={moreInfoItem}
                        onClick={closeMoreInfo}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
}

IllustrationsListBrowser.propTypes = {
    components: PropTypes.object,
};

IllustrationsListBrowser.defaultProps = {
    components: [],
};

export default IllustrationsListBrowser;
