import React from 'react';
import PropTypes from 'prop-types';

function InformationField({ label, value, className, valueStyle, onValueClick }) {
    return (
        <div className={`flex flex-col ${className}`}>
            <p className={'mb-6 text-2xl font-bold text-white'}>{label}</p>
            <p
                className={`text-xl text-white ${valueStyle}`}
                onClick={onValueClick}
            >
                {value}
            </p>
        </div>
    );
}

InformationField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    valueStyle: PropTypes.string,
    onValueClick: PropTypes.func,
};

InformationField.defaultProps = {
    label: '',
    value: '',
    className: '',
    valueStyle: '',
    onValueClick: () => {},
};

export default InformationField;
