import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { distributors } from '../../../constant/distributors';
import { LIGHT } from '../../../constant/navbarTheme';
import NavLink from '../NavLink';
import DropDownMenu from './DropDownMenu';

function DistributorsDropDownMenu({ theme, active }) {
    const [t] = useTranslation(['navbar', 'ourDistributors']);

    const location = useLocation();

    return (
        <DropDownMenu
            title={t('distributors')}
            theme={theme}
            titleActive={active}
        >
            {distributors.map((distributor) => (
                <NavLink
                    key={distributor.key}
                    theme={theme}
                    containerStyle={'border-b border-indotech-orange'}
                    title={t(distributor.title.firstPart, { ns: 'ourDistributors' })}
                    secondTitle={t(distributor.title.highlightedPart, { ns: 'ourDistributors' })}
                    secondTextColor={'text-indotech-orange'}
                    linkTo={distributor.linkTo}
                    active={location.pathname === distributor.linkTo}
                />
            ))}
        </DropDownMenu>
    );
}

DistributorsDropDownMenu.propTypes = {
    theme: PropTypes.string,
    active: PropTypes.bool,
};

DistributorsDropDownMenu.defaultProps = {
    theme: LIGHT,
    active: false,
};

export default DistributorsDropDownMenu;
