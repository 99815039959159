import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Twirl as Hamburger } from 'hamburger-react';

import { getHambugerColor } from '../../utils/styles.js';

import { DARK, LIGHT } from '../../constant/navbarTheme.js';
import DistributorsDrawerContent from './Drawers/DistributorsDrawerContent';
import IndotechDrawer from './Drawers/IndotechDrawer';
import MainDrawerContent from './Drawers/MainDrawerContent';
import ProductsDrawerContent from './Drawers/ProductsDrawerContent';
import NavLogoLink from './NavLogoLink.jsx';

import { HOME } from '../../constant/navigation/routeNames.js';

function MobileNavBar({ theme }) {
    const [mainToggled, setMainToggled] = useState(false);
    const [productsToggled, setProductsToggled] = useState(false);
    const [distributorsToggled, setDistributorsToggled] = useState(false);

    function closeDrawers() {
        setProductsToggled(false);
        setDistributorsToggled(false);
        setMainToggled(false);
    }

    return (
        <div className={`flex w-full flex-row items-center justify-between ${theme === DARK ? 'absolute' : ''} z-10`}>
            <NavLogoLink
                theme={theme}
                linkTo={HOME}
            />

            <div className={'mr-3'}>
                <Hamburger
                    color={getHambugerColor(theme)}
                    toggled={mainToggled}
                    toggle={setMainToggled}
                />
            </div>

            <IndotechDrawer
                toggled={mainToggled}
                setToggled={setMainToggled}
                anchor={'bottom'}
                theme={theme}
            >
                <MainDrawerContent
                    theme={theme}
                    setProductsToggled={setProductsToggled}
                    setDistributorsToggled={setDistributorsToggled}
                    onLinkClick={closeDrawers}
                />
            </IndotechDrawer>

            <IndotechDrawer
                toggled={productsToggled}
                setToggled={setProductsToggled}
                anchor={'right'}
                paperProps={{ width: '100%' }}
                theme={theme}
            >
                <ProductsDrawerContent
                    theme={theme}
                    setProductsToggled={setProductsToggled}
                    onLinkClick={closeDrawers}
                />
            </IndotechDrawer>

            <IndotechDrawer
                toggled={distributorsToggled}
                setToggled={setDistributorsToggled}
                anchor={'right'}
                paperProps={{ width: '100%' }}
                theme={theme}
            >
                <DistributorsDrawerContent
                    theme={theme}
                    setDistributorsToggled={setDistributorsToggled}
                    onLinkClick={closeDrawers}
                />
            </IndotechDrawer>
        </div>
    );
}

MobileNavBar.propTypes = {
    theme: PropTypes.oneOf([LIGHT, DARK]),
};

MobileNavBar.defaultProps = {
    theme: LIGHT,
};

export default MobileNavBar;
