/* eslint-disable no-undef */
import { useEffect, useState } from 'react';

function useScrollStyle() {
    const [backgroundColor, setBackgroundColor] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, []);

    const listenScrollEvent = () => {
        if (window.scrollY > 100) {
            setBackgroundColor('rgba(30,30,30, 0.95)');
        } else {
            setBackgroundColor('');
        }
    };

    return backgroundColor;
}

export default useScrollStyle;
