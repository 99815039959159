import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import doorImg from '../../assets/pictures/components/door_illustration.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SectionTitle from '../ui-elements/SectionTitle';
import IllustrationListMobile from './IllustrationListMobile';
import IllustrationsListBrowser from './IllustrationsListBrowser';

function TechIllustrations({ components }) {
    const [t] = useTranslation('ourProducts');

    const { isMobile } = useWindowDimensions();

    return (
        <div className={'flex w-full max-w-screen-xl flex-col'}>
            <SectionTitle
                title={t('illustrations')}
                classStyle={'mb-10 text-indotech-primary_blue'}
            />

            <div className={'flex flex-col sm:flex-row'}>
                <div className={'mb-6 flex sm:mb-0 sm:basis-1/3'}>
                    <img
                        src={doorImg}
                        alt={'door image'}
                    />
                </div>

                {isMobile ? <IllustrationListMobile components={components} /> : <IllustrationsListBrowser components={components} />}
            </div>
        </div>
    );
}

TechIllustrations.propTypes = {
    components: PropTypes.object,
};

TechIllustrations.defaultProps = {
    components: [],
};

export default TechIllustrations;
