import React from 'react';

import { Distributors as DistributorEnum } from '../../enums/Distributors';

import AboutUsPage from '../../pages/AboutUsPage.jsx';
import ContactUs from '../../pages/ContactUs';
import Distributor from '../../pages/Distributor';
import LandingPage from '../../pages/LandingPage.jsx';
import Quote from '../../pages/Quote';
import Realisations from '../../pages/Realisations';
import TechnicalInformationsPage from '../../pages/TechnicalInformationsPage';
import TechProduct from '../../pages/TechProduct';
import { distributors } from '../distributors';
import { productKeys } from '../products/products';
import {
    ABOUT,
    ANDRE_INDUSTRIES,
    APEX,
    CFM_GROUP,
    CONTACT,
    DEK,
    DRAKKAR,
    HOME,
    LG_DOORS,
    LONGPRE_DOORS,
    QUOTE,
    REALISATIONS,
    ROOT,
    SIMS,
    TECH_INFO,
    TECH_ROLL_LHR,
    TECH_ROLL_LITE,
    TECH_ROLL_MEGA,
    TECH_ROLL_PRO,
    TECH_ROLL_ULTRA,
    UNIVERSAL_DOORS,
    VALMART,
} from './routeNames.js';

export const mainPages = [
    {
        path: ROOT,
        element: <LandingPage />,
    },
    {
        path: HOME,
        element: <LandingPage />,
    },
    {
        path: ABOUT,
        element: <AboutUsPage />,
    },
    {
        path: REALISATIONS,
        element: <Realisations />,
    },
    {
        path: TECH_INFO,
        element: <TechnicalInformationsPage />,
    },
    {
        path: CONTACT,
        element: <ContactUs />,
    },
    {
        path: QUOTE,
        element: <Quote />,
    },
];

export const distributorPages = [
    {
        path: LG_DOORS,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.LG_DOORS)} />,
    },
    {
        path: LONGPRE_DOORS,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.LONGPRE_DOORS)} />,
    },
    {
        path: CFM_GROUP,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.CFM_GROUP)} />,
    },
    {
        path: UNIVERSAL_DOORS,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.UNIVERSAL_DOORS)} />,
    },
    {
        path: ANDRE_INDUSTRIES,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.ANDRE_INDUSTRIES)} />,
    },
    {
        path: DEK,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.DEK)} />,
    },
    {
        path: SIMS,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.SIMS)} />,
    },
    {
        path: VALMART,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.VALMART)} />,
    },
    {
        path: APEX,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.APEX)} />,
    },
    {
        path: DRAKKAR,
        element: <Distributor distributor={distributors.find((distributor) => distributor.key === DistributorEnum.DRAKKAR)} />,
    },
];

export const productPages = [
    {
        path: TECH_ROLL_PRO,
        element: <TechProduct productKey={productKeys.PRO} />,
    },
    {
        path: TECH_ROLL_ULTRA,
        element: <TechProduct productKey={productKeys.ULTRA} />,
    },
    {
        path: TECH_ROLL_LHR,
        element: <TechProduct productKey={productKeys.LHR} />,
    },
    {
        path: TECH_ROLL_LITE,
        element: <TechProduct productKey={productKeys.LITE} />,
    },
    {
        path: TECH_ROLL_MEGA,
        element: <TechProduct productKey={productKeys.MEGA} />,
    },
];
