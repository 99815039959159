import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import DimensionsInput from './DimensionsInput';

function Dimensions({ form, setForm }) {
    const [t] = useTranslation('quote');

    return (
        <>
            <IndotechFormTitle
                title={t('sections.dimensions.sectionTitle')}
                classStyle={'pb-4'}
            />

            <DimensionsInput
                form={form}
                setForm={setForm}
                doorNumber={1}
            />

            <DimensionsInput
                form={form}
                setForm={setForm}
                doorNumber={2}
            />

            <DimensionsInput
                form={form}
                setForm={setForm}
                doorNumber={3}
            />
        </>
    );
}

Dimensions.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

Dimensions.defaultProps = {
    form: {},
    setForm: () => {},
};

export default Dimensions;
