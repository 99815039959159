import React from 'react';
import PropTypes from 'prop-types';

function L5LinkText({ href, classStyle, value }) {
    if (!href) {
        return <div className={classStyle}>{value}</div>;
    }

    return (
        <a
            className={classStyle}
            href={href}
            target={'_blank'}
            rel="noreferrer"
        >
            {value}
        </a>
    );
}

L5LinkText.propTypes = {
    classStyle: PropTypes.string,
    email: PropTypes.string,
};

L5LinkText.defaultProps = {
    classStyle: '',
    email: '',
};

export default L5LinkText;
