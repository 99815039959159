import React from 'react';

import Footer from '../components/footer/Footer';
import Banner from '../components/realisations/Banner';
import IndoTechGallery from '../components/realisations/IndotechGallery';
import AnimatedPage from '../components/ui-elements/AnimatedPage';

export default function Realisations() {
    return (
        <AnimatedPage>
            <Banner />

            <IndoTechGallery />

            <Footer />
        </AnimatedPage>
    );
}
