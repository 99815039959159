import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import IndotechTextInput from '../../ui-elements/IndotechTextInput';

function Industry({ form, setForm }) {
    const [t] = useTranslation('contactUs');

    return (
        <>
            <IndotechFormTitle title={t('industry')} />
            <div className={`mt-4 sm:flex`}>
                <IndotechTextInput
                    label={t('industry_name')}
                    type={'text'}
                    autocomplete={'new-password'} // useful to remove random autocomplete that happens otherwise
                    onChange={(e) => setForm({ ...form, industry: e.target.value })}
                    withMarginRight={true}
                    value={form.industry}
                />

                <IndotechTextInput
                    label={t('product_range')}
                    onChange={(e) => setForm({ ...form, productRange: e.target.value })}
                    value={form.productRange}
                />
            </div>
        </>
    );
}

Industry.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

Industry.defaultProps = {
    form: {},
    setForm: () => {},
};

export default Industry;
