import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const GuideTypes = {
    PIERCED: 'Pierced',
    NON_PIERCED: 'Non Pierced',
};

function GuideRails({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        { value: GuideTypes.PIERCED, label: t('sections.guides.pierced') },
        { value: GuideTypes.NON_PIERCED, label: t('sections.guides.nonPierced') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.guides.sectionTitle')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={false}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, guides: e })}
                selected={form.guides}
                itemContainerStyle={'my-2 sm:flex'}
            />
        </>
    );
}

GuideRails.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

GuideRails.defaultProps = {
    form: {},
    setForm: () => {},
};

export default GuideRails;
