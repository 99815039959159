import React from 'react';
import PropTypes from 'prop-types';

import L5Separator from './L5Separator';

function IndotechSeparator({ classStyle }) {
    return <L5Separator classStyle={`mx-16 my-8 border border-indotech-primary_blue ${classStyle}`} />;
}

L5Separator.propTypes = {
    className: PropTypes.string,
};

L5Separator.defaultProps = {
    className: '',
};

export default IndotechSeparator;
