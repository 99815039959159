import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../../utils/Colors';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import IndotechFormTitle from '../../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../../ui-elements/L5RadioButton/L5RadioGroup';

function CurtainColor({ curtainOptions, selectedCurtain, setSelectedCurtain }) {
    const [t] = useTranslation('quote');

    const { isMobile } = useWindowDimensions();

    if (!curtainOptions.length) {
        return (
            <>
                <IndotechFormTitle
                    title={t('sections.doorModel.subSections.curtain.title')}
                    classStyle={'pb-2'}
                    isSubtitle={true}
                />

                <div className={'my-1 ml-4 text-indotech-primary_blue sm:ml-12'}>{t('sections.doorModel.subSections.curtain.black')}</div>
            </>
        );
    }

    return (
        <>
            <IndotechFormTitle
                title={t('sections.doorModel.subSections.curtain.title')}
                classStyle={'pb-2'}
                isSubtitle={true}
            />

            <L5RadioGroup
                options={curtainOptions}
                horizontal={!isMobile}
                color={Colors.primary_blue}
                setSelected={setSelectedCurtain}
                selected={selectedCurtain}
                formStyle={'ml-4 sm:ml-12'}
                itemContainerStyle={'my-2 sm:mr-32 sm:flex'}
            />
        </>
    );
}

CurtainColor.propTypes = {
    curtainOptions: PropTypes.object,
    selectedCurtain: PropTypes.string,
    setSelectedCurtain: PropTypes.func,
};

CurtainColor.defaultProps = {
    curtainOptions: [],
    selectedCurtain: '',
    setSelectedCurtain: () => {},
};

export default CurtainColor;
