import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

// EN
import aboutUsEN from '../locale/en/about-us.json';
import contactUsEN from '../locale/en/contact-us.json';
import introEN from '../locale/en/intro.json';
import navbarEN from '../locale/en/navbar.json';
import ourDistributorsEN from '../locale/en/our-distributors.json';
import ourProductsEN from '../locale/en/our-products.json';
import ourTeamEN from '../locale/en/our-team.json';
import quoteEN from '../locale/en/quote.json';
import realisationsEN from '../locale/en/realisations.json';
// FR
import aboutUsFR from '../locale/fr/about-us.json';
import contactUsFR from '../locale/fr/contact-us.json';
import introFR from '../locale/fr/intro.json';
import navbarFR from '../locale/fr/navbar.json';
import ourDistributorsFR from '../locale/fr/our-distributors.json';
import ourProductsFR from '../locale/fr/our-products.json';
import ourTeamFR from '../locale/fr/our-team.json';
import quoteFR from '../locale/fr/quote.json';
import realisationsFR from '../locale/fr/realisations.json';

const supportedLanguages = ['en', 'fr'];

const browserLanguage = navigator.language;
const language = browserLanguage.split('-')[0];

const localLanguage = localStorage.getItem('indotechLocale')?.toLowerCase();
const defaultLanguage = supportedLanguages.includes(language) ? language : 'en';

i18n.use(initReactI18next).init({
    lng: localLanguage ? localLanguage : defaultLanguage,
    resources: {
        en: {
            navbar: navbarEN,
            intro: introEN,
            ourDistributors: ourDistributorsEN,
            aboutUs: aboutUsEN,
            ourTeam: ourTeamEN,
            contactUs: contactUsEN,
            ourProducts: ourProductsEN,
            realisations: realisationsEN,
            quote: quoteEN,
        },
        fr: {
            navbar: navbarFR,
            intro: introFR,
            ourDistributors: ourDistributorsFR,
            aboutUs: aboutUsFR,
            ourTeam: ourTeamFR,
            contactUs: contactUsFR,
            ourProducts: ourProductsFR,
            realisations: realisationsFR,
            quote: quoteFR,
        },
    },
});

export default i18n;
