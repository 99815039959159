import React from 'react';
import { useTranslation } from 'react-i18next';

import { distributors } from '../../constant/distributors';
import DistributorCard from '../distributors/DistributorCard';
import SectionTitle from '../ui-elements/SectionTitle';

function OurDistributors() {
    const [t] = useTranslation('ourDistributors');

    return (
        <div className={'w-screen max-w-screen-xl'}>
            <div className={'flex flex-col items-center p-11'}>
                <SectionTitle
                    title={t('our_distributors').toUpperCase()}
                    classStyle={'m-4 text-center text-indotech-primary_blue'}
                />

                <div className={'m-4 w-3/4 text-center text-xl text-indotech-primary_blue sm:w-2/5'}>{t('our_distributors_text')}</div>
            </div>

            <div className={'mx-2 mt-6 flex flex-wrap justify-center'}>
                {distributors.map((distributor) => (
                    <DistributorCard
                        key={distributor.title.fullName}
                        logo={distributor.logo}
                        title={t(distributor.title.fullName)}
                        description={distributor.description}
                        linkTo={distributor.linkTo}
                    />
                ))}
            </div>
        </div>
    );
}

export default OurDistributors;
