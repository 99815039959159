import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IndotechFormTitle from '../../../ui-elements/IndotechFormTitle';
import TechnicalDetailList from './TechnicalDetailList';

function TechnicalDetail({ technicalDetails }) {
    const [t] = useTranslation('quote');

    return (
        <>
            <IndotechFormTitle
                title={t('sections.doorModel.subSections.technicalDetails')}
                classStyle={'mt-4 pb-2'}
                isSubtitle={true}
            />

            <TechnicalDetailList technicalDetails={technicalDetails} />
        </>
    );
}

TechnicalDetail.propTypes = {
    technicalDetails: PropTypes.object,
};

TechnicalDetail.defaultProps = {
    technicalDetails: [],
};

export default TechnicalDetail;
