import { LHR_PICTURES, LITE_PICTURES, MEGA_PICTURES, PRO_PICTURES, ULTRA_PICTURES } from '../../components/realisations/IndotechGallery';

import lhrBannerImage from '../../assets/pictures/LHR/door_LHR_devant.png';
import liteBannerImage from '../../assets/pictures/LITE/door_LITE_green.bmp';
import megaBannerImage from '../../assets/pictures/MEGA/door_MEGA_photo-15.jpg';
import proBannerImage from '../../assets/pictures/PRO/door_PRO_viarail.jpg';
import ultraBannerImage from '../../assets/pictures/ULTRA/door_ULTRA_AMT-Trans-de-lest.JPG';
import { lhrBenefits, lhrComponents, lhrInfoKeys, lhrPdfs, lhrSpecificationKeys } from './techrollLhr';
import { liteBenefits, liteComponents, liteInfoKeys, litePdfs, liteSpecificationKeys } from './techrollLite';
import { megaBenefits, megaComponents, megaInfoKeys, megaPdfs, megaSpecificationKeys } from './techrollMega';
import { proBenefits, proComponents, proInfoKeys, proPdfs, proSpecificationKeys } from './techrollPro';
import { ultraBenefits, ultraComponents, ultraInfoKeys, ultraPdfs, ultraSpecificationKeys } from './techrollUltra';

import { TECH_ROLL_LHR, TECH_ROLL_LITE, TECH_ROLL_MEGA, TECH_ROLL_PRO, TECH_ROLL_ULTRA } from '../navigation/routeNames';

export const productKeys = {
    PRO: 'pro',
    ULTRA: 'ultra',
    LHR: 'lhr',
    LITE: 'lite',
    MEGA: 'mega',
};

export const products = [
    {
        titleKey: productKeys.ULTRA,
        linkTo: TECH_ROLL_ULTRA,
        bannerImage: ultraBannerImage,
        pdfs: ultraPdfs,
        infoKeys: ultraInfoKeys,
        benefits: ultraBenefits,
        specificationKeys: ultraSpecificationKeys,
        components: ultraComponents,
        videoId: 'X6v-gPT5AZA',
        images: ULTRA_PICTURES,
    },
    {
        titleKey: productKeys.PRO,
        linkTo: TECH_ROLL_PRO,
        bannerImage: proBannerImage,
        pdfs: proPdfs,
        infoKeys: proInfoKeys,
        benefits: proBenefits,
        specificationKeys: proSpecificationKeys,
        components: proComponents,
        videoId: 'X6v-gPT5AZA',
        images: PRO_PICTURES,
    },
    {
        titleKey: productKeys.MEGA,
        linkTo: TECH_ROLL_MEGA,
        bannerImage: megaBannerImage,
        pdfs: megaPdfs,
        infoKeys: megaInfoKeys,
        benefits: megaBenefits,
        specificationKeys: megaSpecificationKeys,
        components: megaComponents,
        videoId: 'X6v-gPT5AZA',
        images: MEGA_PICTURES,
    },
    {
        titleKey: productKeys.LHR,
        linkTo: TECH_ROLL_LHR,
        bannerImage: lhrBannerImage,
        pdfs: lhrPdfs,
        infoKeys: lhrInfoKeys,
        benefits: lhrBenefits,
        specificationKeys: lhrSpecificationKeys,
        components: lhrComponents,
        videoId: 'l14QgV8oM7Y',
        images: LHR_PICTURES,
    },
    {
        titleKey: productKeys.LITE,
        linkTo: TECH_ROLL_LITE,
        bannerImage: liteBannerImage,
        pdfs: litePdfs,
        infoKeys: liteInfoKeys,
        benefits: liteBenefits,
        specificationKeys: liteSpecificationKeys,
        components: liteComponents,
        isLastItem: true,
        videoId: 'X6v-gPT5AZA',
        images: LITE_PICTURES,
    },
];
