export const ROOT = '/';
export const HOME = '/home';
export const ABOUT = '/about';
export const REALISATIONS = '/realisations';
export const CONTACT = '/contact';
export const QUOTE = '/request-a-quote';
export const APEX = '/apex-industries';
export const DEK = '/dek-canada';
export const CFM_GROUP = '/groupe-cfm';
export const LG_DOORS = '/portes-lg';
export const ANDRE_INDUSTRIES = '/les-industries-andre';
export const UNIVERSAL_DOORS = '/porte-de-garage-universelles';
export const LONGPRE_DOORS = '/portes-longpre';
export const SIMS = '/sims';
export const VALMART = '/valmart';
export const DRAKKAR = '/drakkar';
export const TECH_INFO = '/technical-information';
export const TECH_ROLL_PRO = '/tech-roll-pro';
export const TECH_ROLL_ULTRA = '/tech-roll-ultra';
export const TECH_ROLL_LHR = '/tech-roll-lhr';
export const TECH_ROLL_LITE = '/tech-roll-lite';
export const TECH_ROLL_MEGA = '/tech-roll-mega';
