import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const AnimatedPage = ({ children, className }) => {
    const { isMobile } = useWindowDimensions();

    const animations = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    return (
        <motion.div
            variants={isMobile ? {} : animations}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.2, type: '', ease: 'easeIn' }}
            className={className}
        >
            {children}
        </motion.div>
    );
};

AnimatedPage.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
};

AnimatedPage.defaultProps = {
    children: <></>,
    className: '',
};

export default AnimatedPage;
