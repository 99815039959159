import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const CommunicationMethod = {
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
};

function PreferredCommunicationMethod({ form, setForm }) {
    const [t] = useTranslation('contactUs');

    const options = [
        { value: CommunicationMethod.EMAIL, label: t('email') },
        { value: CommunicationMethod.PHONE, label: t('phone') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('preferred_communication_method')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={true}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, communication: e })}
                selected={form.communication}
                itemContainerStyle={`w-80 sm:flex`}
            />
        </>
    );
}

PreferredCommunicationMethod.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

PreferredCommunicationMethod.defaultProps = {
    form: {},
    setForm: () => {},
};

export default PreferredCommunicationMethod;
