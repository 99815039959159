import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function ValuesCard({ title, description, logo }) {
    const [t] = useTranslation('aboutUs');

    return (
        <div className={'my-6 w-full sm:w-1/3 sm:pr-12'}>
            <img
                src={logo}
                alt={'logo'}
            />

            <div className={'my-2 text-3xl text-white sm:my-4'}>{t(title)}</div>

            <div className={'text-justify text-xl text-white'}>{t(description)}</div>
        </div>
    );
}

ValuesCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
};

ValuesCard.defaultProps = {
    title: '',
    description: '',
    logo: '',
};

export default ValuesCard;
