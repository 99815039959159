import React from 'react';
import PropTypes from 'prop-types';

import DoorRadioComponent from './DoorRadioComponent';

function DoorRadioComponentList({ selected, setSelected, selectedCurtain, setSelectedCurtain, options }) {
    function handleOptionChange(changeEvent) {
        setSelected(changeEvent.target.value);
    }

    return options.map((option) => (
        <DoorRadioComponent
            key={option.value}
            value={option.value}
            label={option.label}
            labelSuffix={option.labelSuffix}
            selectedOption={selected}
            handleOptionChange={handleOptionChange}
            curtainOptions={option.curtainOptions}
            selectedCurtain={selectedCurtain}
            setSelectedCurtain={setSelectedCurtain}
            technicalDetails={option.technicalDetails}
        />
    ));
}

DoorRadioComponentList.propTypes = {
    selected: PropTypes.string,
    setSelected: PropTypes.func,
    selectedCurtain: PropTypes.string,
    setSelectedCurtain: PropTypes.func,
    options: PropTypes.object,
};

DoorRadioComponentList.defaultProps = {
    selected: '',
    setSelected: () => {},
    selectedCurtain: '',
    setSelectedCurtain: () => {},
    options: [],
};

export default DoorRadioComponentList;
