import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TeamMemberCard({ name, position, text, picture }) {
    const [t] = useTranslation('ourTeam');

    return (
        <div className={'my-5 flex w-full flex-col sm:items-center sm:pr-12'}>
            <div className={'flex flex-col sm:flex-row'}>
                <img
                    src={picture}
                    className={'w-44 rounded-xl sm:h-56'}
                    alt={'member_picture'}
                />

                <div className={'py-4 sm:ml-8 sm:self-center sm:px-4 sm:py-0'}>
                    <div className={'mb-2 text-lg font-bold text-indotech-primary_blue sm:text-3xl'}>{name}</div>

                    <div className={'mb-2 text-base font-semibold text-indotech-primary_blue sm:text-2xl'}>{t(position)}</div>

                    <div className={'text-justify text-sm text-indotech-primary_blue sm:text-xl'}>{t(text)}</div>
                </div>
            </div>
        </div>
    );
}

TeamMemberCard.propTypes = {
    name: PropTypes.string,
    position: PropTypes.string,
    text: PropTypes.string,
    picture: PropTypes.string,
};

TeamMemberCard.defaultProps = {
    name: '',
    position: '',
    text: '',
    picture: '',
};

export default TeamMemberCard;
