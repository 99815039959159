import React from 'react';
import PropTypes from 'prop-types';

function Section({ children, classStyle }) {
    return <div className={`w-full ${classStyle} flex justify-center`}>{children}</div>;
}

Section.propTypes = {
    children: PropTypes.element.isRequired,
    classStyle: PropTypes.string,
};

Section.defaultProps = {
    classStyle: '',
};

export default Section;
