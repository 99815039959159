import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Colors } from '../../../utils/Colors';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import L5RadioGroup from '../../ui-elements/L5RadioButton/L5RadioGroup';

export const DoorTypes = {
    HIGH_CYCLE: 'High Cycling',
    HIGH_SPEED: 'High-Speed',
    GENERAL: 'General Usage',
};

function DoorType({ form, setForm }) {
    const [t] = useTranslation('quote');

    const { isMobile } = useWindowDimensions();

    const options = [
        { value: DoorTypes.HIGH_CYCLE, label: t('sections.doorType.highCycle') },
        { value: DoorTypes.HIGH_SPEED, label: t('sections.doorType.highSpeed') },
        { value: DoorTypes.GENERAL, label: t('sections.doorType.generalUsage') },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.doorType.sectionTitle')}
                classStyle={'pb-4'}
            />

            <L5RadioGroup
                options={options}
                horizontal={!isMobile}
                color={Colors.primary_blue}
                setSelected={(e) => setForm({ ...form, doorType: e })}
                selected={form.doorType}
                itemContainerStyle={'my-2 sm:flex sm:w-80'}
            />
        </>
    );
}

DoorType.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

DoorType.defaultProps = {
    form: {},
    setForm: () => {},
};

export default DoorType;
