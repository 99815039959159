import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getModalBgColor } from '../../../utils/styles';

import { LIGHT } from '../../../constant/navbarTheme';
import NavLink from '../NavLink';

function DropDownMenu({ title, titleActive, theme, titleLink, children }) {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <div>
            <div className={'flex w-full'}>
                <div
                    className={''}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <NavLink
                        title={title}
                        theme={theme}
                        linkTo={titleLink}
                        active={titleActive}
                    />

                    {isDropdownVisible && (
                        <div className={`absolute z-10 rounded border border-indotech-orange ${getModalBgColor(theme)} pr-4`}>{children}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

DropDownMenu.propTypes = {
    title: PropTypes.string,
    theme: PropTypes.string,
    titleLink: PropTypes.string,
    titleActive: PropTypes.bool,
};

DropDownMenu.defaultProps = {
    title: '',
    theme: LIGHT,
    titleLink: null,
    titleActive: false,
};

export default DropDownMenu;
