import React from 'react';

import Footer from '../components/footer/Footer';
import OurProductsList from '../components/our-products/OurProductsList';
import AnimatedPage from '../components/ui-elements/AnimatedPage';
import Section from '../components/ui-elements/Section';

function TechnicalInformationsPage() {
    return (
        <AnimatedPage>
            <Section classStyle={'px-12 pt-4 sm:pt-24'}>
                <OurProductsList />
            </Section>

            <Footer />
        </AnimatedPage>
    );
}

export default TechnicalInformationsPage;
