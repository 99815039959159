import React from 'react';
import PropTypes from 'prop-types';

import { DARK, LIGHT } from '../../constant/navbarTheme';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import BrowserNavbar from './BrowserNavbar';
import MobileNavBar from './MobileNavBar';

function Navbar({ theme }) {
    const { isMobile } = useWindowDimensions();

    if (isMobile) {
        return <MobileNavBar theme={theme} />;
    }

    return <BrowserNavbar theme={theme} />;
}

Navbar.propTypes = {
    theme: PropTypes.oneOf([DARK, LIGHT]),
};

Navbar.defaultProps = {
    theme: LIGHT,
};

export default Navbar;
