import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

function ProductCardFooter({ linkTo }) {
    const [t] = useTranslation('ourProducts');

    return (
        <div className={'my-2 flex items-center justify-end'}>
            <Link
                className={'flex flex-row text-sm font-semibold text-indotech-primary_blue underline hover:opacity-50 sm:text-base'}
                to={linkTo}
            >
                {t('learn_more').toUpperCase()}

                <MdKeyboardArrowRight
                    size={25}
                    color={Colors.primary_blue}
                />
            </Link>
        </div>
    );
}

ProductCardFooter.propTypes = {
    linkTo: PropTypes.string,
};

ProductCardFooter.defaultProps = {
    linkTo: '',
};

export default ProductCardFooter;
