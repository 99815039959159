import React from 'react';
import PropTypes from 'prop-types';

function L5RadioDot({ outerStyle, innerStyle, color, radioChecked }) {
    return (
        <span
            className={`flex ${outerStyle} mt-0.5 flex-shrink-0 items-center justify-center self-start rounded-full border-[1.5px] p-0.5 transition-all duration-100 ease-in-out sm:mt-0.5`}
            style={{
                borderColor: color,
            }}
        >
            <span
                className={`${innerStyle} rounded-full`}
                style={{ backgroundColor: radioChecked ? color : '', transition: 'all .3s ease' }}
            />
        </span>
    );
}

L5RadioDot.propTypes = {
    outerStyle: PropTypes.string,
    innerStyle: PropTypes.string,
    color: PropTypes.string,
    radioChecked: PropTypes.bool,
};

L5RadioDot.defaultProps = {
    outerStyle: '',
    innerStyle: '',
    color: '',
    radioChecked: false,
};

export default L5RadioDot;
