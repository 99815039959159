import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SpecificationValue from './SpecificationValue';

function SpecificationItem({ specificationKey, index }) {
    const [t] = useTranslation('ourProducts');

    return (
        <div className={`${index % 2 === 1 ? 'bg-gray-100' : 'bg-gray-300'} flex flex-row items-center justify-between px-4 py-3.5 sm:px-10`}>
            <div className={'mr-1 flex basis-2/5 font-semibold text-indotech-primary_blue sm:text-lg'}>{t(specificationKey.label)}</div>

            <SpecificationValue specificationValue={specificationKey.value} />
        </div>
    );
}

SpecificationItem.propTypes = {
    specificationKey: PropTypes.string,
    index: PropTypes.number,
};

SpecificationItem.defaultProps = {
    specificationKey: '',
    index: 0,
};

export default SpecificationItem;
