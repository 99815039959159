import React from 'react';

import { contact } from '../../constant/contact';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function FooterContactInfo() {
    const { isMobile } = useWindowDimensions();

    return (
        <div className={'justify mx-3 flex flex-col sm:mx-6'}>
            <a
                className={'text-2xs font-semibold text-white hover:cursor-pointer hover:opacity-50 sm:text-sm'}
                href={`mailTo:${contact.EMAIL.value}`}
            >
                {contact.EMAIL.value}
            </a>

            <a
                className={'text-xs font-semibold text-white sm:text-sm'}
                href={isMobile && `tel:${contact.PHONE.secondValue}`}
            >
                {contact.PHONE.secondValue}
            </a>
        </div>
    );
}

export default FooterContactInfo;
