import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { productKeys } from '../../../constant/products/products';
import IndotechFormTitle from '../../ui-elements/IndotechFormTitle';
import DoorRadioGroup from './door-model-radio/DoorRadioGroup';

export const Models = {
    PRO: productKeys.PRO,
    ULTRA: productKeys.ULTRA,
    LHR: productKeys.LHR,
    LITE: productKeys.LITE,
    MEGA: productKeys.MEGA,
};

export const Curtains = {
    PRO_SBR: 'SBR BLACK (PRO)',
    PRO_EPDM: 'EPDM BLUE (PRO)',
    PRO_MSHA: 'MSHA BLACK (PRO)',
    ULTRA_SBR: 'SBR BLACK (ULTRA)',
    ULTRA_EPDM: 'EPDM BLUE (ULTRA)',
    ULTRA_MSHA: 'MSHA BLACK (ULTRA)',
    LHR_SBR: 'SBR BLACK (LHR)',
    LHR_EPDM: 'EPDM BLUE (LHR)',
    MEGA_SBR: 'SBR BLACK (MEGA)',
    MEGA_EPDM: 'EPDM BLUE (MEGA)',
    MEGA_MSHA: 'MSHA BLACK (MEGA)',
};

function DoorModel({ form, setForm }) {
    const [t] = useTranslation('quote');

    const options = [
        {
            value: Models.PRO,
            label: t('sections.doorModel.models.pro.title').toUpperCase(),
            curtainOptions: [
                { value: Curtains.PRO_SBR, label: t('sections.doorModel.subSections.curtain.sbr') },
                { value: Curtains.PRO_EPDM, label: t('sections.doorModel.subSections.curtain.epdm') },
                { value: Curtains.PRO_MSHA, label: t('sections.doorModel.subSections.curtain.msha') },
            ],
            technicalDetails: [
                t('sections.doorModel.models.pro.technicalDetails.curtainWidth'),
                t('sections.doorModel.models.pro.technicalDetails.operator'),
                t('sections.doorModel.models.pro.technicalDetails.guides'),
                t('sections.doorModel.models.pro.technicalDetails.finish'),
                t('sections.doorModel.models.pro.technicalDetails.springs'),
                t('sections.doorModel.models.pro.technicalDetails.antiwind'),
                t('sections.doorModel.models.pro.technicalDetails.bottomBar'),
                t('sections.doorModel.models.pro.technicalDetails.brush'),
                t('sections.doorModel.models.pro.technicalDetails.bands'),
                t('sections.doorModel.models.pro.technicalDetails.securityBorder'),
                t('sections.doorModel.models.pro.technicalDetails.ideal'),
                t('sections.doorModel.models.pro.technicalDetails.minClearance'),
            ],
        },
        {
            value: Models.ULTRA,
            label: t('sections.doorModel.models.ultra.title').toUpperCase(),
            curtainOptions: [
                { value: Curtains.ULTRA_SBR, label: t('sections.doorModel.subSections.curtain.sbr') },
                { value: Curtains.ULTRA_EPDM, label: t('sections.doorModel.subSections.curtain.epdm') },
                { value: Curtains.ULTRA_MSHA, label: t('sections.doorModel.subSections.curtain.msha') },
            ],
            technicalDetails: [
                t('sections.doorModel.models.ultra.technicalDetails.curtainWidth'),
                t('sections.doorModel.models.ultra.technicalDetails.operator'),
                t('sections.doorModel.models.ultra.technicalDetails.guides'),
                t('sections.doorModel.models.ultra.technicalDetails.finish'),
                t('sections.doorModel.models.ultra.technicalDetails.springs'),
                t('sections.doorModel.models.ultra.technicalDetails.antiwind'),
                t('sections.doorModel.models.ultra.technicalDetails.bottomBar'),
                t('sections.doorModel.models.ultra.technicalDetails.brush'),
                t('sections.doorModel.models.ultra.technicalDetails.bands'),
                t('sections.doorModel.models.ultra.technicalDetails.securityBorder'),
                t('sections.doorModel.models.ultra.technicalDetails.ideal'),
                t('sections.doorModel.models.ultra.technicalDetails.minClearance'),
            ],
        },
        {
            value: Models.LHR,
            label: t('sections.doorModel.models.lhr.title').toUpperCase(),
            labelSuffix: t('sections.doorModel.models.lhr.titleSuffix'),
            curtainOptions: [
                { value: Curtains.LHR_SBR, label: t('sections.doorModel.subSections.curtain.sbr') },
                { value: Curtains.LHR_EPDM, label: t('sections.doorModel.subSections.curtain.epdm') },
            ],
            technicalDetails: [
                t('sections.doorModel.models.lhr.technicalDetails.maxHeight'),
                t('sections.doorModel.models.lhr.technicalDetails.curtainWidth'),
                t('sections.doorModel.models.lhr.technicalDetails.operator'),
                t('sections.doorModel.models.lhr.technicalDetails.guides'),
                t('sections.doorModel.models.lhr.technicalDetails.finish'),
                t('sections.doorModel.models.lhr.technicalDetails.springs'),
                t('sections.doorModel.models.lhr.technicalDetails.configPanel'),
                t('sections.doorModel.models.lhr.technicalDetails.bottomBar'),
                t('sections.doorModel.models.lhr.technicalDetails.brush'),
                t('sections.doorModel.models.lhr.technicalDetails.bands'),
                t('sections.doorModel.models.lhr.technicalDetails.securityBorder'),
                t('sections.doorModel.models.lhr.technicalDetails.ideal'),
                t('sections.doorModel.models.lhr.technicalDetails.minClearance'),
            ],
        },
        {
            value: Models.LITE,
            label: t('sections.doorModel.models.lite.title').toUpperCase(),
            labelSuffix: t('sections.doorModel.models.lite.titleSuffix'),
            technicalDetails: [
                t('sections.doorModel.models.lite.technicalDetails.curtainWidth'),
                t('sections.doorModel.models.lite.technicalDetails.guides'),
                t('sections.doorModel.models.lite.technicalDetails.finish'),
                t('sections.doorModel.models.lite.technicalDetails.springs'),
                t('sections.doorModel.models.lite.technicalDetails.bottomBar'),
                t('sections.doorModel.models.lite.technicalDetails.brush'),
                t('sections.doorModel.models.lite.technicalDetails.bands'),
                t('sections.doorModel.models.lite.technicalDetails.securityBorder'),
                t('sections.doorModel.models.lite.technicalDetails.ideal'),
                t('sections.doorModel.models.lite.technicalDetails.durable'),
                t('sections.doorModel.models.lite.technicalDetails.minClearance'),
            ],
        },
        {
            value: Models.MEGA,
            label: t('sections.doorModel.models.mega.title').toUpperCase(),
            curtainOptions: [
                { value: Curtains.MEGA_SBR, label: t('sections.doorModel.subSections.curtain.sbr') },
                { value: Curtains.MEGA_EPDM, label: t('sections.doorModel.subSections.curtain.epdm') },
                { value: Curtains.MEGA_MSHA, label: t('sections.doorModel.subSections.curtain.msha') },
            ],
            technicalDetails: [
                t('sections.doorModel.models.mega.technicalDetails.curtainWidth'),
                t('sections.doorModel.models.mega.technicalDetails.operator'),
                t('sections.doorModel.models.mega.technicalDetails.operator_2'),
                t('sections.doorModel.models.mega.technicalDetails.guides'),
                t('sections.doorModel.models.mega.technicalDetails.finish'),
                t('sections.doorModel.models.mega.technicalDetails.antiwind'),
                t('sections.doorModel.models.mega.technicalDetails.bottomBar'),
                t('sections.doorModel.models.mega.technicalDetails.brush'),
                t('sections.doorModel.models.mega.technicalDetails.bands'),
                t('sections.doorModel.models.mega.technicalDetails.securityBorder'),
                t('sections.doorModel.models.mega.technicalDetails.ideal'),
                t('sections.doorModel.models.mega.technicalDetails.minClearance'),
            ],
        },
    ];

    return (
        <>
            <IndotechFormTitle
                title={t('sections.doorModel.sectionTitle')}
                classStyle={'pb-4'}
            />

            <DoorRadioGroup
                options={options}
                setSelected={(e) => setForm({ ...form, doorModel: e })}
                selected={form.doorModel}
                setSelectedCurtain={(e) => setForm({ ...form, curtain: e })}
                selectedCurtain={form.curtain}
            />
        </>
    );
}

DoorModel.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
};

DoorModel.defaultProps = {
    form: {},
    setForm: () => {},
};

export default DoorModel;
