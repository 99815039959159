import React from 'react';
import PropTypes from 'prop-types';

import { l5RadioButtonStyle } from '../../../utils/styles';

import { L5RadioButtonSize } from '../../../enums/L5RadioButtonSize';

import L5RadioDot from './L5RadioDot';
import L5RadioLabel from './L5RadioLabel';

function L5RadioButton({ value, label, selectedOption, handleOptionChange, color, size, itemContainerStyle, textStyle }) {
    const radioChecked = selectedOption === value;

    const radioStyle = l5RadioButtonStyle(size);

    return (
        <div className={`radio ${itemContainerStyle}`}>
            <label className={'flex cursor-pointer items-center'}>
                <input
                    type={'radio'}
                    value={value}
                    checked={radioChecked}
                    onChange={handleOptionChange}
                    className={'peer h-0 w-0 appearance-none'}
                />

                <L5RadioDot
                    outerStyle={radioStyle.outer}
                    innerStyle={radioStyle.inner}
                    radioChecked={radioChecked}
                    color={color}
                />

                <L5RadioLabel
                    label={label}
                    textStyle={`${radioStyle.text} ${textStyle}`}
                    color={color}
                />
            </label>
        </div>
    );
}

L5RadioButton.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    selectedOption: PropTypes.string,
    handleOptionChange: PropTypes.func,
    color: PropTypes.string,
    itemContainerStyle: PropTypes.string,
    textStyle: PropTypes.string,
    size: PropTypes.oneOf([L5RadioButtonSize.LG, L5RadioButtonSize.MD, L5RadioButtonSize.SM]),
};

L5RadioButton.defaultProps = {
    value: '',
    label: '',
    selectedOption: '',
    handleOptionChange: () => {},
    color: '#000000',
    itemContainerStyle: '',
    textStyle: '',
    size: L5RadioButtonSize.MD,
};

export default L5RadioButton;
