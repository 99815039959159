import { useState } from 'react';

import i18n from '../plugins/i18n';

import { Lang } from '../enums/Lang';

function useLanguage() {
    const [lang, setLang] = useState(i18n.language);

    const language = () => {
        if (lang === Lang.EN) {
            return Lang.FR;
        }

        return Lang.EN;
    };

    async function changeLocale() {
        setLang(language());
        await i18n.changeLanguage(language());
    }

    return [language().toUpperCase(), changeLocale];
}

export default useLanguage;
