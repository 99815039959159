import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function SpecificationTabledValue({ specificationValue }) {
    const [t] = useTranslation('ourProducts');

    const labelValuePairs = specificationValue.map((spec, index) => (
        <div
            key={spec.label}
            className={`mx-1 flex flex-row ${index === 0 ? '' : 'border-t'} border-gray-400 py-1`}
        >
            <div className={`flex-1 items-center justify-end ${spec.value ? 'text-right' : 'text-left'} text-indotech-primary_blue`}>{t(spec.label)}</div>
            {spec.value && <div className={'flex-1 items-center justify-end text-right text-indotech-primary_blue'}>{t(spec.value)}</div>}
        </div>
    ));

    return <div className={'flex basis-3/5 flex-col'}>{labelValuePairs}</div>;
}

SpecificationTabledValue.propTypes = {
    specificationValue: PropTypes.object,
};

SpecificationTabledValue.defaultProps = {
    specificationValue: [],
};

export default SpecificationTabledValue;
