import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Colors } from '../../utils/Colors';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProductCardPdfs from './ProductCardPdfs';

function ProductCardContent({ valueKey, pdfsList }) {
    const { isMobile } = useWindowDimensions();

    const [t] = useTranslation('ourProducts');

    return (
        <div className={'sm:min-h-60'}>
            <div className={'my-3 items-center text-justify text-sm font-semibold text-indotech-primary_blue sm:my-4 sm:text-base'}>
                {t(`products.${valueKey}.description`)}
            </div>

            {pdfsList.length > 0 && (
                <div className={'my-3 flex flex-row items-center sm:my-4'}>
                    <IoIosInformationCircle
                        size={isMobile ? 20 : 25}
                        className={'mr-1 sm:mr-2'}
                        color={Colors.primary_blue}
                    />

                    <div className={'text-sm text-indotech-primary_blue sm:text-base'}>{t('technicalInformation')}</div>
                </div>
            )}

            <ProductCardPdfs
                pdfsList={pdfsList}
                checkLocale={true}
            />
        </div>
    );
}

ProductCardContent.propTypes = {
    valueKey: PropTypes.string,
    pdfsList: PropTypes.object,
};

ProductCardContent.defaultProps = {
    valueKey: '',
    pdfsList: [],
};

export default ProductCardContent;
