import React from 'react';
import PropTypes from 'prop-types';

import IllustrationsItem from './IllustrationItem';

function IllustrationListMobile({ components }) {
    return components.map((component) => (
        <IllustrationsItem
            key={component.id}
            component={component}
        />
    ));
}

IllustrationListMobile.propTypes = {
    components: PropTypes.object,
};

IllustrationListMobile.defaultProps = {
    components: [],
};

export default IllustrationListMobile;
