import React from 'react';
import PropTypes from 'prop-types';

import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';

import useWindowDimensions from '../../hooks/useWindowDimensions';

function IndotechMap({ coordinates }) {
    const { isMobile } = useWindowDimensions();

    const mobileStyle = `h-[200px]`;
    const browserStyle = `flex flex-1 items-center justify-center p-20`;

    return (
        <div className={`${isMobile ? mobileStyle : browserStyle} border border-indotech-primary_blue`}>
            <div className={`flex h-full flex-1 overflow-hidden border sm:rounded-2xl`}>
                <Map
                    mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                    defaultZoom={15}
                    defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
                >
                    <AdvancedMarker position={coordinates}>
                        <Pin
                            background={'#FBBC04'}
                            glyphColor={'#000'}
                            borderColor={'#000'}
                        />
                    </AdvancedMarker>
                </Map>
            </div>
        </div>
    );
}

IndotechMap.propTypes = {
    coordinates: PropTypes.object,
};

IndotechMap.defaultProps = {
    coordinates: {
        lat: 0,
        lng: 0,
    },
};

export default IndotechMap;
