import React, { useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoMdCloseCircle } from 'react-icons/io';
import PropTypes from 'prop-types';

import { Modal } from '@mui/material';

import { L5Keys } from '../../enums/L5Keys';

import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function L5ImagePreviewModal({ isOpened, setOpened, images, selectedImage, setSelectedImage }) {
    const { isMobile } = useWindowDimensions();

    const closeModal = () => {
        setOpened(false);
    };

    const onPreviousClicked = () => {
        if (selectedImage === 0) {
            setSelectedImage(images.length - 1);
            return;
        }

        setSelectedImage(selectedImage - 1);
    };

    const onNextClicked = () => {
        if (selectedImage === images.length - 1) {
            setSelectedImage(0);
            return;
        }

        setSelectedImage(selectedImage + 1);
    };

    const handleKeyPress = (event) => {
        switch (event.key) {
            case L5Keys.ARROW_LEFT:
                onPreviousClicked();
                break;
            case L5Keys.ARROW_RIGHT:
                onNextClicked();
                break;
            case L5Keys.ESCAPE:
                closeModal();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keyup', handleKeyPress);

        return () => {
            window.removeEventListener('keyup', handleKeyPress);
        };
    }, [selectedImage]);

    if (!isOpened) {
        return null;
    }

    return (
        <Modal
            open={isOpened}
            className="flex bg-[rgb(0,0,0,0.6)]"
        >
            <div className="flex w-full select-none flex-col outline-0">
                <div className={'absolute right-2 m-8 flex'}>
                    <IoMdCloseCircle
                        color={'white'}
                        size={50}
                        className="hover:cursor-pointer hover:opacity-50"
                        onClick={closeModal}
                    />
                </div>

                <div className={`flex w-full flex-1 flex-${isMobile ? 'col' : 'row'} items-center justify-center overflow-x-auto`}>
                    {!isMobile && (
                        <IoIosArrowBack
                            color={'white'}
                            size={50}
                            className="hover:cursor-pointer hover:opacity-50"
                            onClick={onPreviousClicked}
                        />
                    )}

                    <img
                        src={images[selectedImage].src}
                        className={`${isMobile ? 'max-h-96' : 'mx-20 h-2/3 w-2/3'} object-contain`}
                        alt={''}
                    />

                    <div className={`flex flex-row ${isMobile && 'fixed bottom-10 mt-12 w-full justify-between px-8'}`}>
                        {isMobile && (
                            <IoIosArrowBack
                                color={'white'}
                                size={50}
                                className="hover:cursor-pointer hover:opacity-50"
                                onClick={onPreviousClicked}
                            />
                        )}

                        <IoIosArrowForward
                            color={'white'}
                            size={50}
                            className="hover:cursor-pointer hover:opacity-50"
                            onClick={onNextClicked}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

L5ImagePreviewModal.protoTypes = {
    isOpened: PropTypes.string,
    setOpened: PropTypes.func,
    images: PropTypes.array,
    selectedImage: PropTypes.number,
    setSelectedImage: PropTypes.func,
};

L5ImagePreviewModal.defaultProps = {
    isOpened: false,
    setOpened: () => {},
    images: [],
    selectedImage: 0,
    setSelectedImage: () => {},
};
